import accommodationAgeGroupedTotalCapacityObject from '@/entities/accommodationAgeGroupedTotalCapacity/interface'
import accommodationCapacitySettingsObject from '@/entities/accommodationCapacitySettings/interface'
import ratioAccommodationExtensionMapItemObject from '@/entities/ratioAccommodationExtensionMapItem/interface'
import ratioAccommodationObject from '@/entities/ratioAccommodation/interface'
import ratioAgeGroupServiceObject from '@/entities/ratioAgeGroupService/interface'
import ratioBookingAddonObject from '@/entities/ratioBookingAddon/interface'
import ratioCollectiveAddonObject from '@/entities/ratioCollectiveAddon/interface'
import ratioExtensionAccommodationObject from '@/entities/ratioExtensionAccommodation/interface'
import ratioFamilyPackageObject from '@/entities/ratioFamilyPackage/interface'
import ratioPersonalAddonObject from '@/entities/ratioPersonalAddon/interface'
import ratioTravelDateObject from '@/entities/ratioTravelDate/interface'
import ratioTravelExtensionObject from '@/entities/ratioTravelExtension/interface'
import ratioTravelObject from '@/entities/ratioTravel/interface'
import travelObject from '@/entities/travel/interface'
import travelPricingAgeGroupObject from '@/entities/travelPricingAgeGroup/interface'
import {
  accommodationAddonSelectionItem,
  bookingStateItem,
  bookingStateTraveller,
  packageContingent,
  serviceContingent,
  accommodationSelection,
  personalAddonSelection,
  bookingAddonSelection,
  collectiveAddonSelection,
  accommodationSelectionItem,
  bookingStateInsurance,
  bookingStateBaby,
  personalAddonSelectionItem,
  collectiveAddonSelectionItem,
  bookingAddonSelectionItem,
  travelDocumentationOption,
  personalDiscountSelectionItem,
  personalDiscountSelection,
  BusStopSelectionType,
  BusRideDirection,
  BusStopItem,
  RatioBusStopItem,
  RatioBusStationItem,
  bargainDiscountSelection,
  EsimService,
  BookingEsimServiceSelection,
} from '@/features/booking/types'
import { TFunction } from 'next-i18next'
import { formatPrice, toUiDate } from '@/lib/helpers/helpers'
import travelAvailabilityStatusObject from '@/entities/travelAvailabilityStatus/interface'
import availabilityStatusTenantSettingsObject from '@/entities/availabilityStatusTenantSettings/interface'
import { doMandatoryRulesApply, doMandatorySettingsApply, getAccommodationCapacityCount } from '@/features/booking/helpers'
import ratioPersonalDiscountObject from '@/entities/ratioPersonalDiscount/interface'
import tenantOnlinePaymentConfigObject from '@/entities/tenantOnlinePaymentConfig/interface'
import ratioEsimConfigObject from '@/entities/ratioEsimConfig/interface'

type booking = {
  travelId: number
  currentStep: number
  travel: travelObject
  availabilityStatus?: travelAvailabilityStatusObject
  defaultAvailabilityStatus?: travelAvailabilityStatusObject
  defaultWaitlistFallbackAvailabilityStatus?: travelAvailabilityStatusObject
  switchedToWaitlistFallback: boolean
  ageGroups: travelPricingAgeGroupObject[]
  pricingTravel: ratioTravelObject
  travelDate: ratioTravelDateObject
  travellers: bookingStateTraveller[]
  babies: bookingStateBaby[]
  contingents: {
    packages: packageContingent[]
    services: serviceContingent[]
  }
  accommodationSelection: accommodationSelection
  accommodationAddonSelection: accommodationAddonSelectionItem[]
  personalAddonSelection: personalAddonSelection
  bookingAddonSelection: bookingAddonSelection
  collectiveAddonSelection: collectiveAddonSelection
  personalDiscountSelection: personalDiscountSelection
  bargainDiscountSelection: bargainDiscountSelection
  familyInsuranceSelection?: bookingStateInsurance
  travelDocumentationService: 'print' | 'electronic'
  isUpgradedToExtension: boolean
  extensionDowngradeDate?: ratioTravelDateObject
  ratioSummary?: any
  co2compensation: boolean
  payment: {
    type: 'online' | 'invoice'
    transactionId?: string
  }
  esimConfig: ratioEsimConfigObject
  esimServiceSelection: BookingEsimServiceSelection
  onlinePaymentConfig: tenantOnlinePaymentConfigObject
}

export default class LocalBooking {
  readonly booking: booking

  constructor(booking: bookingStateItem) {
    this.booking = booking as booking
  }

  getCurrency(): string {
    // @ts-ignore
    const backofficeCurrency = this.booking?.pricingTravel?.ratioCatalogBundle?.json.t_katalogreise.data.anzahlungsart
    return backofficeCurrency === 'EUR' ? 'EUR' : 'CHF'
  }

  formatPrice(price: number): string {
    return formatPrice(price, this.getCurrency())
  }

  getAvailabilityStatus(): travelAvailabilityStatusObject {
    if (this.booking?.availabilityStatus) {
      return this.booking.availabilityStatus
    }
    return this.getInitialAvailabilityStatus()
  }

  private static getFallbackAvailabilityStatus(): travelAvailabilityStatusObject {
    return {
      label: '',
      status: 'BOOKABLE',
      isDefaultStatus: true,
      isDefaultWaitlistFallback: false,
      tenantSettings: [],
    }
  }

  private getInitialAvailabilityStatus(): travelAvailabilityStatusObject {
    if (this.booking?.travelDate?.availabilityStatus) {
      return this.booking.travelDate.availabilityStatus
    }
    if (this.booking?.travel?.availabilityStatus) {
      return this.booking.travel.availabilityStatus
    }
    if (this.booking?.defaultAvailabilityStatus) {
      return this.booking.defaultAvailabilityStatus
    }
    return LocalBooking.getFallbackAvailabilityStatus()
  }

  getSwitchToAvailabilityStatus(): travelAvailabilityStatusObject {
    if (!this.booking.switchedToWaitlistFallback && this.booking.defaultWaitlistFallbackAvailabilityStatus) {
      return this.booking.defaultWaitlistFallbackAvailabilityStatus
    }
    return this.getInitialAvailabilityStatus()
  }

  getAvailabilityStatusTenantSettings(availabilityStatus?: travelAvailabilityStatusObject): availabilityStatusTenantSettingsObject {
    if (!availabilityStatus) {
      availabilityStatus = this.getAvailabilityStatus()
    }

    let tenantSettings
    if (availabilityStatus.tenantSettings) {
      tenantSettings = availabilityStatus.tenantSettings.find((tenantSettings) => {
        return tenantSettings.tenant.id == process.env.NEXT_PUBLIC_TENANT_ID
      })
    }

    if (!tenantSettings) {
      // @ts-ignore
      return {
        label: '',
        info: '',
        switchToStatusInfo: '',
        submitButtonLabel: '',
      }
    }

    return tenantSettings
  }

  getAvailableTravelDates() {
    if (this.booking && this.booking.pricingTravel) {
      return this.booking.pricingTravel.dates
    }
    return []
  }

  // modifiers
  setAccommodationSelection(selection: { accommodation: ratioAccommodationObject; quantity: number }[]) {
    this.unsetTravellerAccommodations()
    // add given quantity of accommodation to next travellers without accommodation
    selection.forEach((item) => {
      const roomAgeGroupType = this.getRoomAgeGroupType(item.accommodation.capacitySettings)
      let openQuantity = item.quantity
      this.booking.travellers.forEach((traveller) => {
        if (openQuantity > 0) {
          // add if traveller has no accommodation
          if (!this.travellerHasAccommodation(traveller) && this.getTravellerAgeGroupType(traveller) === roomAgeGroupType) {
            traveller.accommodation = item.accommodation
            openQuantity--
          }
        }
      })
    })
  }

  unsetTravellerAccommodations() {
    this.booking.travellers.map((traveller) => {
      // @ts-ignore
      delete traveller.accommodation
    })
  }

  setFamilyPackage(familyPackage: ratioFamilyPackageObject | null) {
    this.unsetFamilyPackage()
    if (familyPackage) {
      // assign familyPackage (Service) to first traveller
      this.booking.travellers[0].familyPackage = familyPackage

      // assign accommodation (Package) to all travellers
      this.booking.travellers.forEach((traveller) => {
        traveller.accommodation = familyPackage.accommodation
      })
    }
  }

  unsetFamilyPackage() {
    this.booking.travellers.map((traveller) => {
      // @ts-ignore
      delete traveller.accommodation
      // @ts-ignore
      delete traveller.familyPackage
    })
  }

  // helpers

  getAdultAgeGroup(ageGroups: travelPricingAgeGroupObject[]) {
    const adultAgeGroup = ageGroups.find((ageGroup) => {
      return ageGroup.type === 'ADULT'
    })

    return adultAgeGroup ? adultAgeGroup : ageGroups[0]
  }

  childrenAllowed() {
    let allowed = false
    if (!this.booking.ageGroups) {
      return allowed
    }
    this.booking.ageGroups.forEach((ageGroup) => {
      if (ageGroup.type === 'MINOR') {
        allowed = true
      }
    })
    return allowed
  }

  babiesAllowed(): boolean {
    if (!this.booking.travel) {
      return false
    }
    return !!(this.booking.travel.pricing.ageGroupSettings.freeUntilAge > 0 && this.getBabyPackage())
  }

  getAccommodationTotalCapacity(capacitySettings: accommodationCapacitySettingsObject) {
    const groupedCapacity = this.getGroupedAccommodationCapacity(capacitySettings)
    return groupedCapacity.adults + groupedCapacity.minors
  }

  getGroupedAccommodationCapacity(capacitySettings: accommodationCapacitySettingsObject) {
    let capacities = {
      adults: 0,
      minors: 0,
    }
    if (!capacitySettings.rangesAllowed) {
      // simply count maxCapacity of all groups
      capacitySettings.ageGroupCapacities.map((ageGroupCapacity) => {
        switch (ageGroupCapacity.ageGroup.type) {
          case 'ADULT':
            capacities.adults += ageGroupCapacity.maxCapacity
            break
          case 'MINOR':
            capacities.minors += ageGroupCapacity.maxCapacity
            break
        }
      })
    } else {
      // if has one ADULT group -> count towards 'adults'
      capacitySettings.ageGroupedTotalCapacities.map((ageGroupedTotalCapacity: accommodationAgeGroupedTotalCapacityObject) => {
        const hasAdultAgeGroups = !!ageGroupedTotalCapacity.ageGroups.filter((ageGroup) => {
          return ageGroup.type === 'ADULT'
        }).length
        if (hasAdultAgeGroups) {
          capacities.adults += ageGroupedTotalCapacity.capacity
        } else {
          capacities.minors += ageGroupedTotalCapacity.capacity
        }
      })
    }
    return capacities
  }

  getRoomAgeGroupType(capacitySettings: accommodationCapacitySettingsObject) {
    const capacity = this.getGroupedAccommodationCapacity(capacitySettings)
    return capacity.adults ? 'ADULT' : 'MINOR'
  }

  getTravellerAgeGroupType(traveller: bookingStateTraveller) {
    return traveller.ageGroup.type
  }

  getTravellersByAgeGroup(ageGroup: travelPricingAgeGroupObject, travellers?: bookingStateTraveller[]) {
    if (!travellers) {
      travellers = this.booking.travellers
    }
    return travellers.filter((traveller) => {
      return traveller.ageGroup.id === ageGroup.id
    })
  }

  getTravellersByAgeGroupType(type: 'ADULT' | 'MINOR', travellers?: bookingStateTraveller[]) {
    if (!travellers) {
      travellers = this.booking.travellers
    }
    return travellers.filter((traveller) => {
      return traveller.ageGroup.type === type
    })
  }

  travellerHasAccommodation(traveller: bookingStateTraveller) {
    return !!traveller.accommodation
  }

  travellerIsFamilyPackageMember(traveller: bookingStateTraveller) {
    return !!traveller.isFamilyPackageMember
  }

  getTravellersWithoutAccommodation(): bookingStateTraveller[] {
    return this.booking.travellers.filter((traveller) => {
      return !this.travellerHasAccommodation(traveller)
    })
  }

  getGroupedTravellersWithoutAccommodation() {
    const openTravellers = this.getTravellersWithoutAccommodation()
    return {
      adults: openTravellers.filter((traveller) => {
        return traveller.ageGroup.type === 'ADULT'
      }),
      minors: openTravellers.filter((traveller) => {
        return traveller.ageGroup.type === 'MINOR'
      }),
    }
  }

  // getTravellersWithoutAccommodationByAgeGroupType(type: 'ADULT' | 'MINOR') {
  //   const openTravellers = this.getTravellersWithoutAccommodation()
  //   return openTravellers.filter((traveller) => {
  //     return traveller.ageGroup.type === type
  //   })
  // }

  getGroupedTravellersCount() {
    return {
      adults: this.booking.travellers.filter((traveller) => {
        return traveller.ageGroup.type === 'ADULT'
      }).length,
      minors: this.booking.travellers.filter((traveller) => {
        return traveller.ageGroup.type === 'MINOR'
      }).length,
    }
  }

  getRoomMaxCapacity(capacitySettings: accommodationCapacitySettingsObject) {
    let capacities = this.getGroupedAccommodationCapacity(capacitySettings)
    switch (this.getRoomAgeGroupType(capacitySettings)) {
      case 'ADULT':
        return capacities.adults
      case 'MINOR':
        return capacities.minors
    }
  }

  isTravellerSelectionSatisfiable() {
    // any accommodation or family package applicable?
    const anyAccommodationOrFamilyPackageApplicable = this.isAnyAccommodationOrFamilyPackageApplicable()
    const hasAnySinglePersonAccommodation = this.hasAnySinglePersonAccommodation()
    if (!anyAccommodationOrFamilyPackageApplicable) {
      return false
    }

    // /** TODO: check if all travellers can be served
    //  * Problem: if any accommodations are available, it is still possible that not all travellers can be served
    //  * e.g. 3 adults but only double rooms available
    //  * important: the solution must consider all age groups - have fun! :)
    //  */
    if (this.booking.travellers.length == 1 && (hasAnySinglePersonAccommodation || this.hasAnySinglePersonFamilyPackage())) {
      return true
    }
    if (
      this.booking.travellers.length % 2 != 0 &&
      !this.isAnyFamilyPackageApplicable() &&
      !hasAnySinglePersonAccommodation &&
      !anyAccommodationOrFamilyPackageApplicable
    ) {
      return false
    }

    // special case for PKAG: only even number of travellers allowed
    if (process.env.NEXT_PUBLIC_THEME === 'pkag' && this.booking.travellers.length % 2 != 0 && !hasAnySinglePersonAccommodation) {
      return false
    }

    return true
  }

  hasAnySinglePersonAccommodation() {
    return (
      this.booking.pricingTravel.accommodations.filter((accommodation) => {
        return getAccommodationCapacityCount(accommodation.capacitySettings) === 1
      }).length > 0
    )
  }

  hasAnySinglePersonFamilyPackage() {
    return (
      this.booking.pricingTravel.familyPackages.filter((familyPackage) => {
        return getAccommodationCapacityCount(familyPackage.capacitySettings) === 1
      }).length > 0
    )
  }

  isAnyAccommodationOrFamilyPackageApplicable() {
    return this.isAnyAccommodationApplicable() || this.isAnyFamilyPackageApplicable()
  }

  isAnyFamilyPackageApplicable() {
    if (!this.booking.pricingTravel) {
      return false
    }
    return this.booking.pricingTravel.familyPackages.filter((familyPackage) => this.isFamilyPackageApplicable(familyPackage)).length > 0
  }

  isAnyAccommodationApplicable() {
    if (!this.booking.pricingTravel) {
      return false
    }
    return (
      this.booking.pricingTravel.accommodations.filter((accommodation) => {
        if (accommodation.isFamilyPackageAccommodation || accommodation.isBabyPackage) {
          return false
        }
        return this.isAccommodationApplicable(accommodation)
      }).length > 0
    )
  }

  getApplicableDefaultAccommodations() {
    return this.booking.pricingTravel.accommodations.filter((accommodation) => {
      return !accommodation.isFamilyPackageAccommodation && !accommodation.isBabyPackage && !accommodation.capacitySettings.partialOccupancyAllowed
    })
  }

  getApplicablePartialOccupancyAccommodations() {
    return this.booking.pricingTravel.accommodations.filter((accommodation) => {
      return !accommodation.isFamilyPackageAccommodation && accommodation.capacitySettings.partialOccupancyAllowed
    })
  }

  isAccommodationApplicable(accommodation: ratioAccommodationObject) {
    const capacitySettings = accommodation.capacitySettings
    let applicable = true

    if (!capacitySettings.rangesAllowed) {
      // ageGroupCapacities must match
      capacitySettings.ageGroupCapacities.forEach((ageGroupCapacity) => {
        if (this.getTravellersByAgeGroup(ageGroupCapacity.ageGroup).length < ageGroupCapacity.minCapacity) {
          applicable = false
        }
      })
    } else {
      // 1. check if sum of travellers of relevant ageGroups is fulfillable (e.g. min 2 Persons of ageGroups "Child" & "Youth" for a "MINOR double room")
      capacitySettings.ageGroupedTotalCapacities.forEach((ageGroupedTotalCapacity) => {
        let travellersCount = 0
        ageGroupedTotalCapacity.ageGroups.forEach((ageGroup) => {
          travellersCount += this.getTravellersByAgeGroup(ageGroup).length
        })
        if (travellersCount < ageGroupedTotalCapacity.capacity) {
          applicable = false
        }
      })

      // 2. age group capacity must be fulfillable
      if (applicable) {
        accommodation.capacitySettings.ageGroupCapacities.map((ageGroupCapacity) => {
          if (this.getTravellersByAgeGroup(ageGroupCapacity.ageGroup).length < ageGroupCapacity.minCapacity) {
            applicable = false
          }
        })
      }
    }
    return applicable
  }

  isAccommodationSelected(accommodation: ratioAccommodationObject) {
    return !!this.booking.travellers.filter((traveller) => {
      return traveller.accommodation && traveller.accommodation.id === accommodation.id
    }).length
  }

  isAddAccommodationDisabled(accommodation: ratioAccommodationObject) {
    if (this.hasFamilyPackage()) {
      return true
    }

    const travellers = this.getTravellersWithoutAccommodation()
    let disabled = false

    if (!accommodation.capacitySettings.rangesAllowed) {
      accommodation.capacitySettings.ageGroupCapacities.forEach((ageGroupCapacity) => {
        if (this.getTravellersByAgeGroup(ageGroupCapacity.ageGroup, travellers).length < ageGroupCapacity.maxCapacity) {
          disabled = true
        }
      })
    } else {
      // 1. age grouped total capacities must be fulfillable (e.g. min 2 travellers for double room)
      accommodation.capacitySettings.ageGroupedTotalCapacities.forEach((ageGroupedTotalCapacity: accommodationAgeGroupedTotalCapacityObject) => {
        let totalAgeGroupTravellers = 0
        // sum travellers of all assigned ageGroups
        ageGroupedTotalCapacity.ageGroups.map((ageGroup) => {
          totalAgeGroupTravellers += this.getTravellersByAgeGroup(ageGroup, travellers).length
        })

        // only proceed if this group matches
        if (totalAgeGroupTravellers < ageGroupedTotalCapacity.capacity) {
          // console.log(accommodation.label, 'disabled by "age grouped total capacities"', totalAgeGroupTravellers, ageGroupedTotalCapacity.capacity)
          disabled = true
        }
      })

      // 2. age group capacity must be fulfillable
      if (!disabled) {
        accommodation.capacitySettings.ageGroupCapacities.map((ageGroupCapacity) => {
          if (this.getTravellersByAgeGroup(ageGroupCapacity.ageGroup, travellers).length < ageGroupCapacity.minCapacity) {
            // console.log(accommodation.label, 'disabled by "group capacity"')
            disabled = true
          }
        })
      }
    }
    return disabled
  }

  getAccommodationOccupancies(accommodation: ratioAccommodationObject) {
    return {
      adults: this.booking.travellers.filter((traveller) => {
        return traveller.accommodation?.ratioPackageNo == accommodation.ratioPackageNo && traveller.ageGroup.type === 'ADULT'
      }).length,
      minors: this.booking.travellers.filter((traveller) => {
        return traveller.accommodation?.ratioPackageNo == accommodation.ratioPackageNo && traveller.ageGroup.type === 'MINOR'
      }).length,
    }
  }

  private getPackagePrice(packageNo: string | number, travelDate?: ratioTravelDateObject) {
    if (!travelDate) {
      travelDate = this.booking.travelDate
    }
    if (travelDate.includesExtension) {
      // find related extension package
      const extensionMapItem = travelDate.extension.accommodationMapItems.find((item: ratioAccommodationExtensionMapItemObject) => {
        return item.baseAccommodation.ratioPackageNo == packageNo
      })

      // change PackageNo to extension Package
      if (extensionMapItem) {
        packageNo = extensionMapItem.extensionAccommodation.ratioPackageNo
      }
    }

    // @ts-ignore
    const item = travelDate.json.t_packages.data.find((item: any) => {
      return item.nr == packageNo
    })

    return Number(item.derived_preis)
  }

  getAccommodationPrice(packageNo: string | number, travelDate?: ratioTravelDateObject) {
    return this.getPackagePrice(packageNo, travelDate)
  }

  getAccommodationPriceFormatted(packageNo: string | number, travelDate?: ratioTravelDateObject) {
    return this.formatPrice(this.getPackagePrice(packageNo, travelDate))
  }

  getFamilyPackagePrice(familyPackage: ratioFamilyPackageObject, travelDate?: ratioTravelDateObject) {
    if (!travelDate) {
      travelDate = this.booking.travelDate
    }
    // @ts-ignore
    const item = travelDate.json.t_reiseleistungen.data.find((item: any) => {
      return item.leistungnr == familyPackage.ratioServiceNo
    })
    return Number(item.derived_preis)
  }

  getFamilyPackagePriceFormatted(familyPackage: ratioFamilyPackageObject) {
    return this.formatPrice(this.getFamilyPackagePrice(familyPackage))
  }

  getServicePrice(serviceNo: string | number, json?: JSON) {
    if (!json) {
      json = this.booking.travelDate.json
    }
    // @ts-ignore
    const item = json.t_reiseleistungen.data.find((item: any) => {
      return item.leistungnr == serviceNo
    })
    return Number(item.derived_preis)
  }

  getServicePriceFormatted(serviceNo: string | number) {
    return this.formatPrice(this.getServicePrice(serviceNo))
  }

  getFamilyInsurancePrice(serviceNo: string | number) {
    if (this.booking.ratioSummary) {
      const item = this.booking.ratioSummary.data.t_vorgang_versicherungen.data.t_reiseleistungen.data.find((item: any) => {
        return item.leistungnr === serviceNo
      })
      if (item) {
        return Number(item.derived_preis)
      }
    }
    return 0
  }

  getPersonalInsurancePrice(serviceNo: string | number, travellerIndex: number) {
    if (this.booking.ratioSummary) {
      const item = this.booking.ratioSummary.data.t_teilnehmers_versicherungen.data[travellerIndex].t_reiseleistungen.data.find((item: any) => {
        return item.leistungnr === serviceNo
      })
      if (item) {
        return Number(item.derived_preis)
      }
    }
    return 0
  }

  getCollectiveAddonPrice(addon: ratioCollectiveAddonObject) {
    let totalPrice = 0
    this.booking.travellers.forEach((traveller) => {
      const ageGroupService = addon.ageGroupServices.find((ageGroupService) => {
        return ageGroupService.ageGroup.id === traveller.ageGroup.id
      }) as ratioAgeGroupServiceObject
      totalPrice += this.getServicePrice(ageGroupService.ratioServiceNo)
    })
    return totalPrice
  }

  getCollectiveAddonPriceFormatted(addon: ratioCollectiveAddonObject) {
    return this.formatPrice(this.getCollectiveAddonPrice(addon))
  }

  getApplicableFamilyPackages() {
    return this.booking.pricingTravel.familyPackages.filter((familyPackage) => this.isFamilyPackageApplicable(familyPackage))
  }

  getInitialApplicableFamilyPackages() {
    return this.booking.pricingTravel.familyPackages.filter((familyPackage) => this.isFamilyPackageApplicable(familyPackage, this.booking.travellers))
  }

  private isFamilyPackageApplicable(familyPackage: ratioFamilyPackageObject, targetTravellers?: bookingStateTraveller[]) {
    let isApplicable = true

    if (targetTravellers === undefined) {
      // consider all travellers without accommodation or already covered by familyPackage
      targetTravellers = this.getFamilyPackageApplicabilityRelevantTravellers()
    }

    if (isApplicable) {
      switch (familyPackage.capacitySettings.rangesAllowed) {
        case false:
          // exact match of number of travellers per age group capacity (e.g. 2 adults, 2 youth, 1 child)
          familyPackage.capacitySettings.ageGroupCapacities.forEach((ageGroupCapacity) => {
            if (this.getTravellersByAgeGroup(ageGroupCapacity.ageGroup, targetTravellers).length !== ageGroupCapacity.maxCapacity) {
              isApplicable = false
            }
          })
          break

        case true:
          // 1. age grouped total capacities must match (e.g. total 4 travellers for 2+2 Package)
          familyPackage.capacitySettings.ageGroupedTotalCapacities.forEach((ageGroupedTotalCapacity: accommodationAgeGroupedTotalCapacityObject) => {
            let totalAgeGroupTravellers = 0
            // sum travellers of all assigned ageGroups
            ageGroupedTotalCapacity.ageGroups.map((ageGroup) => {
              totalAgeGroupTravellers += this.getTravellersByAgeGroup(ageGroup, targetTravellers).length
            })

            // only proceed if this group matches
            if (totalAgeGroupTravellers !== ageGroupedTotalCapacity.capacity) {
              isApplicable = false
            }
          })

          // 2. age group capacity must match
          if (isApplicable) {
            familyPackage.capacitySettings.ageGroupCapacities.map((ageGroupCapacity) => {
              if (this.getTravellersByAgeGroup(ageGroupCapacity.ageGroup, targetTravellers).length > ageGroupCapacity.maxCapacity) {
                isApplicable = false
              }
            })
          }
          break
      }
    }
    return isApplicable
  }

  getFamilyPackageApplicabilityRelevantTravellers() {
    // consider all travellers without accommodation or already covered by familyPackage
    return this.booking.travellers.filter((traveller) => {
      if (this.travellerIsFamilyPackageMember(traveller)) {
        return true
      }
      if (this.travellerHasAccommodation(traveller)) {
        return false
      }
      return true
    })
  }

  isFamilyPackageAddable(familyPackage: ratioFamilyPackageObject) {
    if (this.hasFamilyPackage()) {
      return false
    }
    return this.isFamilyPackageApplicable(familyPackage)
  }

  hasFamilyPackage() {
    if (!this.booking.travellers.length) {
      return false
    }
    return !!this.booking.travellers[0].familyPackage
  }

  getSelectedFamilyPackage() {
    if (!this.hasFamilyPackage()) {
      return null
    } else {
      return this.booking.travellers[0].familyPackage as ratioFamilyPackageObject
    }
  }

  getBabyPackage() {
    if (!this.booking.pricingTravel) {
      return undefined
    }
    return this.booking.pricingTravel.accommodations.find((accommodation) => {
      return accommodation.isBabyPackage
    })
  }

  hasAnyAccommodationSelected() {
    return this.booking.accommodationSelection.length > 0 || this.hasFamilyPackage()
  }

  hasAnyAddonSelected() {
    return (
      this.booking.personalAddonSelection.length > 0 ||
      this.booking.bookingAddonSelection.length > 0 ||
      this.booking.collectiveAddonSelection.length > 0 ||
      this.booking.babies.length > 0
    )
  }

  hasAddonsToDisplay() {
    // 1. any addons selected
    if (this.hasAnyAddonSelected()) {
      return true
    }

    // 2. any booking or collective addons available
    if (this.booking.pricingTravel.bookingAddons.length > 0 || this.booking.pricingTravel.collectiveAddons.length > 0) {
      return true
    }

    // 3. any non-mandatory, non-rules, personal addons available (if mandatory and applicable, they are already added -> check 1. applies)
    if (
      this.booking.pricingTravel.personalAddons.filter((addon) => {
        return !addon.mandatorySettings.isMandatory
      }).length > 0
    ) {
      return true
    }

    // 4. any non-mandatory, *with rules*, personal addons available (if mandatory and applicable, they are already added -> check 1. applies)
    let isVisible = false
    const nonMandatoryAddonsWithRules = this.booking.pricingTravel.personalAddons
      // filter relevant addons
      .filter((addon) => {
        return !addon.mandatorySettings.isMandatory && addon.mandatorySettings.useRules
      })
      // check rules for each traveller
      .filter((addon) => {
        this.booking.travellers.forEach((traveller) => {
          if (!isVisible) {
            isVisible = doMandatoryRulesApply(addon.mandatorySettings.rules, traveller, this.booking.travelDate as ratioTravelDateObject)
          }
        })
        return isVisible
      })
    if (nonMandatoryAddonsWithRules.length > 0) {
      return true
    }
  }

  hasAnyDiscountSelected() {
    return this.hasAnyPersonalDiscountSelected() || this.hasBargainDiscountApplied()
  }

  hasAnyPersonalDiscountSelected() {
    return this.booking.personalDiscountSelection.length > 0
  }

  hasBargainDiscountApplied() {
    return this.booking.bargainDiscountSelection.length > 0
  }

  hasDiscountsToDisplay() {
    // 1. any discount selected
    if (this.hasAnyDiscountSelected()) {
      return true
    }

    // 3. any non-mandatory, non-rules, personal discount available (if mandatory and applicable, they are already added -> check 1. applies)
    if (
      this.booking.pricingTravel.personalDiscounts.filter((item) => {
        return !item.mandatorySettings.isMandatory
      }).length > 0
    ) {
      return true
    }

    // 4. any non-mandatory, *with rules*, personal discount available (if mandatory and applicable, they are already added -> check 1. applies)
    let isVisible = false
    const nonMandatoryItemsWithRules = this.booking.pricingTravel.personalDiscounts
      // filter relevant discounts
      .filter((item) => {
        return !item.mandatorySettings.isMandatory && item.mandatorySettings.useRules
      })
      // check rules for each traveller
      .filter((item) => {
        this.booking.travellers.forEach((traveller) => {
          if (!isVisible) {
            isVisible = doMandatoryRulesApply(item.mandatorySettings.rules, traveller, this.booking.travelDate as ratioTravelDateObject)
          }
        })
        return isVisible
      })
    if (nonMandatoryItemsWithRules.length > 0) {
      return true
    }

    return false
  }

  getVisiblePersonalDiscounts() {
    return this.booking.pricingTravel.personalDiscounts.filter((item) => {
      const isMandatory = item.mandatorySettings.isMandatory

      const isNotMandatoryAndApplicableToAnyTraveller = !isMandatory && this.getPersonalDiscountMaxQuantity(item) > 0
      const isMandatoryAndAppliedToAnyTraveller = isMandatory && this.getPersonalDiscountQuantity(item) > 0
      return isNotMandatoryAndApplicableToAnyTraveller || isMandatoryAndAppliedToAnyTraveller
    })
  }

  getPersonalDiscountQuantity(discount: ratioPersonalDiscountObject) {
    const item = this.booking.personalDiscountSelection.find((item) => {
      return item.discount.id === discount.id
    })
    return item ? item.quantity : 0
  }

  // Determine number of applicable travellers
  getPersonalDiscountMaxQuantity(discount: ratioPersonalDiscountObject) {
    const contingent = 999
    let applicableTravellers = 0
    const travellers = this.booking.travellers

    // 2. optional: check rules
    if (discount.mandatorySettings.useRules) {
      if (discount.mandatorySettings.isMandatory) {
        applicableTravellers = 0
        this.booking.travellers.map((traveller) => {
          if (doMandatorySettingsApply(discount.mandatorySettings, traveller, this.booking.travelDate as ratioTravelDateObject)) {
            applicableTravellers++
          }
        })
      } else {
        applicableTravellers = travellers.filter((traveller) => {
          return doMandatoryRulesApply(discount.mandatorySettings.rules, traveller, this.booking.travelDate)
        }).length
      }
    }
    return contingent < applicableTravellers ? contingent : applicableTravellers
  }

  public getBargainDiscountItem() {
    if (this.booking.bargainDiscountSelection.length > 0) {
      return this.booking.bargainDiscountSelection[0]
    }
    return undefined
  }

  // contingents
  private useContingents() {
    // disable contingents for availabilityStatus 'WAITLIST' || 'WAITLIST_CONTINGENT_CHECK'
    return !['WAITLIST', 'WAITLIST_CONTINGENT_CHECK'].includes(this.getAvailabilityStatus().status)
  }

  hasAnyZeroContingent() {
    if (!this.useContingents()) {
      return false
    }

    // check familyPackage contingents
    const familyPackagesWithZeroContingent = this.getApplicableFamilyPackages().filter((familyPackage) => {
      return !this.getFamilyPackageContingent(familyPackage)
    })
    if (familyPackagesWithZeroContingent.length) {
      return true
    }

    // check accommodation contingents
    const accommodationsWithZeroContingent = this.getApplicableDefaultAccommodations().filter((accommodation) => {
      return !this.getAccommodationContingent(accommodation)
    })

    if (accommodationsWithZeroContingent.length) {
      return true
    }

    return false
  }

  private getPackageContingent(ratioPackageNo: number | string) {
    if (!this.useContingents() || !this.booking.contingents) {
      return 999
    }
    const item = this.booking.contingents.packages.find((contingent) => {
      return Number(contingent.ratioPackageNo) == Number(ratioPackageNo)
    }) as packageContingent
    if (!item) {
      return 0
    }
    return item.available
  }

  private getServiceContingent(ratioServiceNo: number | string) {
    if (!this.useContingents() || !this.booking.contingents) {
      return 999
    }
    const item = this.booking.contingents.services.find((contingent) => {
      return Number(contingent.ratioServiceNo) == Number(ratioServiceNo)
    }) as serviceContingent
    if (!item) {
      return 0
    }
    return item.available
  }

  getAccommodationContingent(accommodation: ratioAccommodationObject, customCapacitySettings?: accommodationCapacitySettingsObject) {
    const capacitySettings = customCapacitySettings ? customCapacitySettings : accommodation.capacitySettings
    const realAccommodation = this.getAccommodationConsideringExtension(accommodation)
    const contingent = this.getPackageContingent(realAccommodation.ratioPackageNo)
    if (capacitySettings.partialOccupancyAllowed) {
      return contingent
    } else {
      return Math.floor(contingent / this.getAccommodationTotalCapacity(capacitySettings))
    }
  }

  getAccommodationConsideringExtension(accommodation: ratioAccommodationObject): ratioAccommodationObject | ratioExtensionAccommodationObject {
    if (!this.isExtensionDate()) {
      return accommodation
    } else {
      const accommodationMapItem = this.booking.travelDate.extension.accommodationMapItems.find((mapItem) => {
        return mapItem.baseAccommodation.id === accommodation.id
      }) as ratioAccommodationExtensionMapItemObject
      return accommodationMapItem.extensionAccommodation
    }
  }

  isExtensionDate() {
    return this.booking.travelDate && this.booking.travelDate.includesExtension
  }

  getFamilyPackageContingent(familyPackage: ratioFamilyPackageObject) {
    try {
      const accommodation = this.booking.pricingTravel.accommodations.find((accommodation) => {
        return accommodation.ratioPackageNo == familyPackage.accommodation.ratioPackageNo
      })
      return this.getAccommodationContingent(accommodation as ratioAccommodationObject, familyPackage.capacitySettings)
    } catch (e) {
      return 0
    }
  }

  getPersonalAddonContingent(addon: ratioPersonalAddonObject) {
    return this.getServiceContingent(addon.ratioServiceNo)
  }

  getVisibleBookingAddons() {
    return this.booking.pricingTravel.bookingAddons.filter((addon) => {
      const isMandatory = addon.mandatorySettings.isMandatory

      const isNotMandatoryButApplicable = !isMandatory && this.getBookingAddonMaxQuantity(addon) > 0
      const isMandatoryAndAppliedToBooking = isMandatory && this.getBookingAddonQuantity(addon) > 0
      return isNotMandatoryButApplicable || isMandatoryAndAppliedToBooking
    })
  }

  getBookingAddonQuantity(addon: ratioBookingAddonObject) {
    const item = this.booking.bookingAddonSelection.find((item) => {
      return item.addon.id === addon.id
    })
    return item ? item.quantity : 0
  }

  // Determine number of applicable travellers
  getBookingAddonMaxQuantity(addon: ratioBookingAddonObject) {
    const contingent = this.getBookingAddonContingent(addon)
    const travellers = this.booking.travellers
    let maxQuantity = 1

    // optional: check rules
    if (addon.mandatorySettings.useRules) {
      maxQuantity = 0
      travellers.forEach((traveller) => {
        if (doMandatorySettingsApply(addon.mandatorySettings, traveller, this.booking.travelDate as ratioTravelDateObject)) {
          maxQuantity = 1
        }
      })
    }
    return contingent < maxQuantity ? contingent : maxQuantity
  }

  getBookingAddonContingent(addon: ratioBookingAddonObject) {
    return this.getServiceContingent(addon.ratioServiceNo)
  }

  getVisibleCollectiveAddons() {
    return this.booking.pricingTravel.collectiveAddons.filter((addon) => {
      const isMandatory = addon.mandatorySettings.isMandatory

      const isNotMandatoryButApplicable = !isMandatory && this.getCollectiveAddonMaxQuantity(addon) > 0
      const isMandatoryAndAppliedToAnyTraveller = isMandatory && this.getCollectiveAddonQuantity(addon) > 0
      return isNotMandatoryButApplicable || isMandatoryAndAppliedToAnyTraveller
    })
  }

  getCollectiveAddonQuantity(addon: ratioCollectiveAddonObject) {
    const item = this.booking.collectiveAddonSelection.find((item) => {
      return item.addon.id === addon.id
    })
    return item ? item.quantity : 0
  }

  // Determine number of applicable travellers
  getCollectiveAddonMaxQuantity(addon: ratioCollectiveAddonObject) {
    const contingent = this.getCollectiveAddonContingent(addon)
    const travellers = this.booking.travellers
    let maxQuantity = 1

    // optional: check rules
    if (addon.mandatorySettings.useRules) {
      maxQuantity = 0
      travellers.forEach((traveller) => {
        if (doMandatorySettingsApply(addon.mandatorySettings, traveller, this.booking.travelDate as ratioTravelDateObject)) {
          maxQuantity = 1
        }
      })
    }
    return contingent < maxQuantity ? contingent : maxQuantity
  }

  isValidCollectiveAddon(addon: ratioCollectiveAddonObject) {
    let valid = true
    for (const ageGroupService of addon.ageGroupServices) {
      if (!ageGroupService.ratioServiceNo) {
        valid = false
      }
    }
    return valid
  }

  getCollectiveAddonContingent(addon: ratioCollectiveAddonObject) {
    let lowestContingent = 9999
    this.booking.travellers.forEach((traveller) => {
      const ageGroupService = addon.ageGroupServices.find((ageGroupService) => {
        return ageGroupService.ageGroup.id === traveller.ageGroup.id
      }) as ratioAgeGroupServiceObject
      const contingent = this.getServiceContingent(ageGroupService.ratioServiceNo)
      if (contingent < lowestContingent) {
        lowestContingent = contingent
      }
    })
    return lowestContingent
  }

  getExtensionContingent(extension: ratioTravelExtensionObject) {
    if (!this.hasFamilyPackage()) {
      // accommodations | check each extensionPackage contingent
      let lowestContingent = 9999
      extension.accommodationMapItems.forEach((accommodationMapItem) => {
        const accommodationSelectionItem = this.booking.accommodationSelection.find((item: accommodationSelectionItem) => {
          return accommodationMapItem.baseAccommodation.id == item.accommodation.id
        })
        if (accommodationSelectionItem) {
          const packageContingent = this.getPackageContingent(accommodationMapItem.extensionAccommodation.ratioPackageNo)
          let requiredQuantity: number
          if (accommodationSelectionItem.accommodation.capacitySettings.rangesAllowed) {
            requiredQuantity = accommodationSelectionItem.quantity
          } else {
            requiredQuantity =
              accommodationSelectionItem.quantity * this.getAccommodationTotalCapacity(accommodationSelectionItem.accommodation.capacitySettings)
          }

          const contingent = Math.floor(packageContingent / requiredQuantity)
          if (contingent < lowestContingent) {
            lowestContingent = contingent
          }
        }
      })
      return lowestContingent
    } else {
      // familyPackages | check familyPackage extensionAccommodationService
      const familyPackageAccommodation = this.getSelectedFamilyPackage()?.accommodation as ratioAccommodationObject
      const accommodationMapItem = extension.accommodationMapItems.find((accommodationMapItem) => {
        return accommodationMapItem.baseAccommodation.id === familyPackageAccommodation.id
      }) as ratioAccommodationExtensionMapItemObject
      const serviceContingent = this.getPackageContingent(accommodationMapItem.extensionAccommodation.ratioPackageNo)
      const requiredQuantity = this.booking.travellers.length
      return Math.floor(serviceContingent / requiredQuantity)
    }
  }

  getVisiblePersonalAddons() {
    return this.booking.pricingTravel.personalAddons.filter((addon) => {
      const isMandatory = addon.mandatorySettings.isMandatory

      const isNotMandatoryAndApplicableToAnyTraveller = !isMandatory && this.getPersonalAddonMaxQuantity(addon) > 0
      const isMandatoryAndAppliedToAnyTraveller = isMandatory && this.getPersonalAddonQuantity(addon) > 0
      return isNotMandatoryAndApplicableToAnyTraveller || isMandatoryAndAppliedToAnyTraveller
    })
  }

  getPersonalAddonQuantity(addon: ratioPersonalAddonObject) {
    const item = this.booking.personalAddonSelection.find((item) => {
      return item.addon.id === addon.id
    })
    return item ? item.quantity : 0
  }

  // Determine number of applicable travellers
  getPersonalAddonMaxQuantity(addon: ratioPersonalAddonObject) {
    const contingent = this.getPersonalAddonContingent(addon)
    let applicableTravellers = 0

    // 1. filter travellers by allowedAgeGroups
    const travellers: bookingStateTraveller[] = []
    addon.allowedAgeGroups.map((allowedAgeGroup) => {
      Array.prototype.push.apply(travellers, this.getTravellersByAgeGroup(allowedAgeGroup))
    })
    applicableTravellers = travellers.length

    // 2. optional: check rules
    if (addon.mandatorySettings.useRules) {
      if (addon.mandatorySettings.isMandatory) {
        applicableTravellers = 0
        travellers.map((traveller) => {
          if (doMandatorySettingsApply(addon.mandatorySettings, traveller, this.booking.travelDate as ratioTravelDateObject)) {
            applicableTravellers++
          }
        })
      } else {
        applicableTravellers = travellers.filter((traveller) => {
          return doMandatoryRulesApply(addon.mandatorySettings.rules, traveller, this.booking.travelDate)
        }).length
      }
    }
    return contingent < applicableTravellers ? contingent : applicableTravellers
  }

  hasMandatoryAddons() {
    const hasMandatoryPersonalAddons = !!this.booking.personalAddonSelection.filter((item) => {
      return item.mandatory
    }).length

    const hasMandatoryCollectiveAddons = !!this.booking.collectiveAddonSelection.filter((item) => {
      return item.mandatory
    }).length

    const hasMandatoryBookingAddons = !!this.booking.bookingAddonSelection.filter((item) => {
      return item.mandatory
    }).length

    return hasMandatoryPersonalAddons || hasMandatoryCollectiveAddons || hasMandatoryBookingAddons
  }

  getExtensionDates() {
    let date: ratioTravelDateObject
    if (!this.booking.isUpgradedToExtension) {
      // default 'short' date
      date = this.booking.travelDate
    } else {
      // currently extensionDate is selected: get previous 'short' date
      date = this.booking.extensionDowngradeDate as ratioTravelDateObject
    }
    if (this.booking.pricingTravel.extensions.length) {
      return this.booking.pricingTravel.dates.filter((extensionDate) => {
        // @ts-ignore
        return extensionDate.id !== date.id && extensionDate.extensionBaseDate && extensionDate.extensionBaseDate.id === date.id
      })
    } else {
      return []
    }
  }

  getExtensionDatePriceDiff(extensionDate: ratioTravelDateObject) {
    const familyPackage = this.getSelectedFamilyPackage()
    let priceDiff: number = 0

    if (familyPackage) {
      // calc price diff of familyPackages
      const currentPrice = this.getFamilyPackagePrice(
        familyPackage,
        this.booking.isUpgradedToExtension && this.booking.extensionDowngradeDate ? this.booking.extensionDowngradeDate : this.booking.travelDate
      )
      const extensionPrice = this.getFamilyPackagePrice(familyPackage, extensionDate)
      priceDiff = extensionPrice - currentPrice
    }

    // calc price diff for each travellers accommodation
    this.booking.travellers.map((traveller) => {
      if (traveller.accommodation) {
        const currentPrice = this.getPackagePrice(
          traveller.accommodation.ratioPackageNo,
          this.booking.isUpgradedToExtension && this.booking.extensionDowngradeDate ? this.booking.extensionDowngradeDate : this.booking.travelDate
        )
        const extensionPrice = this.getPackagePrice(traveller.accommodation.ratioPackageNo, extensionDate)
        priceDiff = priceDiff + (extensionPrice - currentPrice)
      }
    })

    return priceDiff
  }

  getExtensionDatePriceDiffFormatted(date: ratioTravelDateObject) {
    return this.formatPrice(this.getExtensionDatePriceDiff(date))
  }

  isExtensionStepVisible() {
    if (this.booking.isUpgradedToExtension) {
      return true
    }
    return !!this.getExtensionDates().length
  }

  getNumberOfAdultTravellers() {
    return this.booking.travellers.filter((traveller) => {
      return traveller.ageGroup.type === 'ADULT'
    }).length
  }

  getChildIndex(childTraveller: bookingStateTraveller) {
    const index = this.booking.travellers.findIndex((traveller) => {
      return traveller === childTraveller
    })
    return index - this.getNumberOfAdultTravellers()
  }

  isPassportDataRequired() {
    if (this.booking.pricingTravel) {
      // @ts-ignore
      const item = this.booking.pricingTravel.ratioCatalogBundle.json.t_katalogreise.data.derived_zusatzinfo.find(
        (item: { name: string; wert: string }) => {
          return item.name == 'WEB | Passdaten'
        }
      )
      return item.wert == 'JA'
    }
    return false
  }

  isPassportOnly() {
    return !!this.booking?.travel?.pricing?.passportConfig?.passportOnly
  }

  getBillingAddressMinAge() {
    return Number(process.env.NEXT_PUBLIC_BOOKING_BILLING_ADDRESS_MIN_AGE_YEARS)
  }

  getBillingAddressMaxBirthdayDate(): Date {
    const date = new Date()
    date.setFullYear(date.getFullYear() - this.getBillingAddressMinAge())
    date.setHours(0, 0, 0, 0)
    return date
  }

  isTravellerQualifiedToBeBillingAddress(traveller: bookingStateTraveller) {
    const minAgeYears = Number(process.env.NEXT_PUBLIC_BOOKING_BILLING_ADDRESS_MIN_AGE_YEARS)
    // calculate today - minAgeYears
    const minAgeDate = new Date()
    minAgeDate.setFullYear(minAgeDate.getFullYear() - minAgeYears)

    const travellerBirthdayDate = new Date(traveller.birthday)
    return travellerBirthdayDate.getTime() <= minAgeDate.getTime()
  }

  useSeparateBillingAddress() {
    return !!this.booking.travellers.filter((traveller) => {
      return traveller.isBillingAddress
    }).length
  }

  getAdultTravellerMinBirthday() {
    const adultAgeGroup = this.booking.ageGroups.find((ageGroup) => {
      return ageGroup.type === 'ADULT'
    }) as travelPricingAgeGroupObject

    // calculate date from travel start date
    const date = new Date(this.booking.travelDate.startDate)
    date.setFullYear(date.getFullYear() - adultAgeGroup.maxAge)
    date.setDate(date.getDate() + 1)
    return date
  }

  getAdultTravellerMaxBirthday() {
    const adultAgeGroup = this.booking.ageGroups.find((ageGroup) => {
      return ageGroup.type === 'ADULT'
    }) as travelPricingAgeGroupObject

    // calculate date from travel start date
    const date = new Date(this.booking.travelDate.startDate)
    date.setFullYear(date.getFullYear() - adultAgeGroup.minAge)
    date.setDate(date.getDate() + 1)
    return date
  }

  getNumberOfMissingTravellerAddresses() {
    let count = 0
    this.booking.travellers.forEach((traveller) => {
      if (!traveller.firstName) {
        count++
      }
    })
    return count
  }

  getInsuranceType() {
    // family insurance selected
    if (this.booking.familyInsuranceSelection) {
      return 'family'
    }

    // any traveller has insurance
    let hasTravellerInsurance = false
    this.booking.travellers.forEach((traveller) => {
      if (traveller.insurance) {
        hasTravellerInsurance = true
      }
    })
    if (hasTravellerInsurance) {
      return 'single'
    }

    // default
    return this.booking.travellers.length > 1 ? 'family' : 'single'
  }

  getGroupInsurances(): bookingStateInsurance[] {
    if (this.booking.ratioSummary) {
      const insurances = this.booking.ratioSummary.data.t_vorgang_versicherungen.data.t_leistungen.data
        .map((insurance: any) => {
          const travelServiceItem = this.booking.ratioSummary.data.t_vorgang_versicherungen.data.t_reiseleistungen.data.find((item: any) => {
            return item.leistungnr === insurance.nr
          })
          return {
            ratioServiceNo: insurance.nr,
            ratioTravelServiceNo: travelServiceItem.nr,
            label: insurance.bezeichnung,
            price: travelServiceItem.derived_preis,
            priceFormatted: this.formatPrice(travelServiceItem.derived_preis),
          }
        })
        .filter((insurance: any, index: number, insurances: any[]) => insurances.findIndex((element) => element.nr === insurance.nr) === index)
      insurances.push(this.getNoInsuranceOption())
      return insurances
    } else {
      return []
    }
  }

  getPersonalInsurances(): bookingStateInsurance[] {
    if (this.booking.ratioSummary) {
      const insurances = this.booking.ratioSummary.data.t_teilnehmers_versicherungen.data[0].t_leistungen.data.map((insurance: any) => {
        const travelServiceItem = this.booking.ratioSummary.data.t_teilnehmers_versicherungen.data[0].t_reiseleistungen.data.find((item: any) => {
          return item.leistungnr === insurance.nr
        })
        return {
          ratioServiceNo: insurance.nr,
          ratioTravelServiceNo: travelServiceItem.nr,
          label: insurance.bezeichnung,
          price: travelServiceItem.derived_preis,
          priceFormatted: this.formatPrice(travelServiceItem.derived_preis),
        }
      })
      insurances.push(this.getNoInsuranceOption())
      return insurances
    } else {
      return []
    }
  }

  getNoInsuranceOption() {
    const insurance = this.booking.ratioSummary.data.versicherung_empty.data.t_leistungen.data[0]

    const travelServiceItem = this.booking.ratioSummary.data.versicherung_empty.data.t_reiseleistungen.data.find((item: any) => {
      return item.leistungnr === insurance.nr
    })

    return {
      ratioServiceNo: insurance.nr,
      ratioTravelServiceNo: travelServiceItem.nr,
      label: insurance.bezeichnung,
      price: 0,
      priceFormatted: this.formatPrice(0),
    }
  }

  isNoInsuranceOption(insurance: bookingStateInsurance) {
    return insurance.ratioServiceNo === this.getNoInsuranceOption().ratioServiceNo
  }

  getTravellersWithoutInsurance() {
    if (this.booking.familyInsuranceSelection) {
      return []
    }
    const travellers: bookingStateTraveller[] = []
    this.booking.travellers.forEach((traveller) => {
      if (!traveller.insurance) {
        travellers.push(traveller)
      }
    })
    return travellers
  }

  getTravelDocumentationOptions(): travelDocumentationOption[] {
    if (!this.booking.ratioSummary) {
      return []
    }
    const printPrice = this.getServicePrice(process.env.NEXT_PUBLIC_TRAVEL_DOCUMENTATION_SERVICE_PRINT as string, this.booking.ratioSummary.data)
    const digitalPrice = this.getServicePrice(process.env.NEXT_PUBLIC_TRAVEL_DOCUMENTATION_SERVICE_DIGITAL as string, this.booking.ratioSummary.data)
    return [
      {
        ratioServiceNo: process.env.NEXT_PUBLIC_TRAVEL_DOCUMENTATION_SERVICE_PRINT as string,
        label: 'Reiseunterlagen per Post inkl. Reiseführer in Buchform',
        name: 'print',
        price: printPrice,
        priceFormatted: this.formatPrice(printPrice),
      },
      {
        ratioServiceNo: process.env.NEXT_PUBLIC_TRAVEL_DOCUMENTATION_SERVICE_DIGITAL as string,
        label: 'Elektronische Reiseunterlagen inkl. digitalem Reiseführer',
        name: 'electronic',
        price: digitalPrice,
        priceFormatted: this.formatPrice(digitalPrice),
      },
    ]
  }

  getSelectedTravelDocumentationOption(): travelDocumentationOption {
    return this.getTravelDocumentationOptions().find((option) => {
      return option.name === this.booking.travelDocumentationService
    }) as travelDocumentationOption
  }

  getPaymentMethods() {
    const methods = [
      {
        label: 'Rechnung',
        type: 'invoice',
        info: '',
      },
    ]
    if (
      this.booking.onlinePaymentConfig.enabled &&
      this.getAvailabilityStatus().status === 'BOOKABLE' &&
      !this.booking.pricingTravel.catalogCode.startsWith('G')
    ) {
      methods.push({
        label: 'Onlinezahlung',
        type: 'online',
        info: this.booking.onlinePaymentConfig.infoText,
      })
    }
    return methods
  }

  isCO2CompensationAvailable() {
    try {
      if (this.getServicePrice(process.env.NEXT_PUBLIC_BOOKING_CO2_COMPENSATION_SERVICE as string) > 0) {
        return true
      }
    } catch (e) {}
    return false
  }

  getCO2CompensationTotalPrice() {
    try {
      if (this.getServicePrice(process.env.NEXT_PUBLIC_BOOKING_CO2_COMPENSATION_SERVICE as string) > 0) {
        return this.booking.travellers.length * this.getServicePrice(process.env.NEXT_PUBLIC_BOOKING_CO2_COMPENSATION_SERVICE as string)
      }
    } catch (e) {}
    return 0
  }

  getCO2CompensationTotalPriceFormatted() {
    return this.formatPrice(this.getCO2CompensationTotalPrice())
  }

  /** Bus Boarding **/
  busStopSelectionRequired(): boolean {
    const json: any = this.booking.travelDate.json
    return Boolean(json.t_knotens_hin && json.t_knotens_hin.data.length)
  }

  getBusStopSelectionType(direction: BusRideDirection): BusStopSelectionType {
    const propName = direction === 'outbound' ? 'busStopItemOutbound' : 'busStopItemReturn'
    let type: BusStopSelectionType = 'group'
    const proofItem = this.booking.travellers[0][propName]
    this.booking.travellers.forEach((traveller) => {
      if (traveller[propName]?.stationNo != proofItem?.stationNo) {
        type = 'individual'
      }
    })
    return type
  }

  getBusStopSelectionTypeOutbound(): BusStopSelectionType {
    return this.getBusStopSelectionType('outbound')
  }

  getBusStopSelectionTypeReturn(): BusStopSelectionType {
    return this.getBusStopSelectionType('return')
  }

  private getBusStops(direction: BusRideDirection): BusStopItem[] {
    const json: any = this.booking.travelDate.json
    const propNameSuffix = direction === 'outbound' ? 'hin' : 'rueck'
    if (json[`t_knotens_${propNameSuffix}`] && json[`t_knotens_${propNameSuffix}`].data.length) {
      const length = json[`t_knotens_${propNameSuffix}`] && json[`t_knotens_${propNameSuffix}`].data.length
      // get boarding node
      const boardingNodeNo = json[`t_knotens_${propNameSuffix}`].data[direction === 'outbound' ? 0 : length - 1].nr

      // get boarding stops (all stops in boarding node)
      const ratioBoardingStops: RatioBusStopItem[] = json[`t_knotenhalte_${propNameSuffix}`].data.filter((stopItem: RatioBusStopItem) => {
        return stopItem.knoten === boardingNodeNo
      })

      // build options
      return ratioBoardingStops.map((stopItem: RatioBusStopItem) => {
        const station = json[`t_haltestellen_${propNameSuffix}`].data.find((station: RatioBusStationItem) => {
          return station.nr === stopItem.halt
        })
        return {
          label: `${stopItem.zeit ? stopItem.zeit + ' | ' : ''} ${station.bezeichnung}`,
          stationNo: station.nr,
          stopNo: stopItem.nr,
        }
      })
    }
    return []
  }

  getBusStopsOutbound(): BusStopItem[] {
    return this.getBusStops('outbound')
  }

  getBusStopsReturn(): BusStopItem[] {
    return this.getBusStops('return')
  }

  getBusStopNoFromStationNo(stationNo: string, direction: BusRideDirection): string | false {
    const stops = this.getBusStops(direction)
    const item = stops.find((stopItem: BusStopItem) => {
      return stopItem.stationNo === stationNo
    })
    if (item) {
      return item.stopNo
    }
    return false
  }

  getTravellersWithoutOutboundStop(): bookingStateTraveller[] {
    if (!this.busStopSelectionRequired()) {
      return []
    }
    return this.booking.travellers.filter((traveller) => {
      return !traveller.busStopItemOutbound
    })
  }

  getTravellersWithoutReturnStop(): bookingStateTraveller[] {
    if (!this.busStopSelectionRequired()) {
      return []
    }
    return this.booking.travellers.filter((traveller) => {
      return !traveller.busStopItemReturn
    })
  }

  /** eSIM Services **/
  getEsimServices(): EsimService[] {
    if (!this.booking.esimConfig?.enabled || !this.booking.ratioSummary) {
      return []
    }
    if (this.booking.travel.pricing.esimSettings?.regions && this.booking.travel.pricing.esimSettings.regions.length) {
      // determine active regions | must be assigned in pricing and available in config
      const activeRegions = this.booking.esimConfig.regions.filter((configRegion) => {
        return (
          this.booking.travel.pricing.esimSettings.regions.filter((settingsRegion) => {
            return configRegion.id === settingsRegion.id
          }).length > 0
        )
      })

      // sort regions
      const sortOrder = new Map(this.booking.travel.pricing.esimSettings.regions.map((item, index) => [item.id, index]))
      activeRegions.sort((a, b) => {
        const orderA = sortOrder.get(a.id) ?? Infinity
        const orderB = sortOrder.get(b.id) ?? Infinity
        return orderA - orderB
      })

      // get services by region configs
      const services: EsimService[] = []
      activeRegions.map((region) => {
        services.push(...this.getEsimServicesByPrefix(region.serviceCodePrefix))
      })
      return services
    }

    return []
  }

  getEsimServicesByPrefix(prefix: string): EsimService[] {
    const json = this.booking.ratioSummary.data
    // @ts-ignore
    const travelServices = json.t_reiseleistungen.data.filter((item: any) => {
      return item.code.startsWith(prefix)
    })
    return travelServices.map((travelService: any) => {
      const service = json.t_leistungen.data.find((item: any) => {
        return item.nr == travelService.leistungnr
      })
      return {
        serviceNo: service.nr,
        travelServiceNo: travelService.nr,
        code: travelService.code,
        name: service.bezeichnung,
        label: service.bezeichnung,
        price: travelService.derived_preis,
        priceFormatted: formatPrice(Number(travelService.derived_preis), this.getCurrency()),
      }
    })
  }

  /** Wizard step errors **/
  getCurrentBookingStepErrors(t: TFunction) {
    const errors = []
    switch (this.booking.currentStep) {
      case 0:
        // travellers without accommodation
        if (this.getTravellersWithoutAccommodation().length !== 0) {
          errors.push(
            t('booking.peopleWithoutAccommodationInfo', {
              count: this.booking.travellers.length,
              open: this.getTravellersWithoutAccommodation().length,
            })
          )
        }
        break

      case 2:
        // travellers without outbound stop
        const travellersWithoutOutboundStop = this.getTravellersWithoutOutboundStop().length
        if (travellersWithoutOutboundStop > 0) {
          errors.push(
            t('booking.busStops.numberOfTravellersWithoutOutboundStop', {
              count: this.booking.travellers.length,
              open: travellersWithoutOutboundStop,
            })
          )
        }

        const travellersWithoutReturnStop = this.getTravellersWithoutReturnStop().length
        if (travellersWithoutReturnStop > 0) {
          errors.push(
            t('booking.busStops.numberOfTravellersWithoutReturnStop', {
              count: this.booking.travellers.length,
              open: travellersWithoutReturnStop,
            })
          )
        }

        const travellersWithoutInsurance = this.getTravellersWithoutInsurance().length
        if (travellersWithoutInsurance > 0) {
          errors.push(
            t('booking.numberOfTravellersWithoutInsurance', {
              count: this.booking.travellers.length,
              open: travellersWithoutInsurance,
            })
          )
        }
        break
    }
    return errors
  }

  /** END: Wizard step errors **/

  getTravellerSelectionLabel(t: TFunction) {
    const childrenAllowed = this.childrenAllowed()
    const babiesAllowed = this.babiesAllowed()

    if (!this.booking.travel || !this.booking.travel.pricing) {
      return ''
    }

    let value = ''
    let adultsCount = 0
    let minorsCount = 0

    this.booking.travel.pricing.ageGroupSettings.ageGroups.forEach((ageGroup) => {
      const count = this.booking.travellers.filter((traveller) => {
        return traveller.ageGroup.id === ageGroup.id
      }).length
      switch (ageGroup.type) {
        case 'ADULT':
          adultsCount += count
          break
        case 'MINOR':
          minorsCount += count
          break
      }
    })
    if (adultsCount) {
      value += t(childrenAllowed || babiesAllowed ? 'booking.adultWithCount' : 'booking.personsWithCount', { count: adultsCount })
    }
    if (adultsCount && minorsCount) {
      value += ', ' + t('booking.childrenWithCount', { count: minorsCount })
    }
    if (this.booking.babies.length) {
      value += ', ' + t('booking.babiesWithCount', { count: this.booking.babies.length })
    }
    return value
  }

  getTravellerSummaryContent() {
    if (!this.booking.travel || !this.booking.travel.pricing) {
      return ''
    }

    const allTravellers = [...this.booking.travellers, ...this.booking.babies]
    if (!allTravellers.length) {
      return ''
    }

    const hasValidData = allTravellers.every((traveller) => traveller.firstName && traveller.lastName && traveller.birthday)
    if (!hasValidData) {
      return ''
    }

    let content = ''

    allTravellers.forEach((traveller) => {
      content += `<div class="mb-2"><span class="fw-bold text-uppercase">${traveller.lastName}</span> ${traveller.firstName}<br /><small>${toUiDate(
        traveller.birthday
      )}</small></div>`
    })
    return content
  }

  getAccommodationSelectionLabel() {
    let label = ''
    if (this.hasFamilyPackage()) {
      label += `<li>${this.getSelectedFamilyPackage()?.label}</li>`
    }
    this.booking.accommodationSelection.forEach((item) => {
      label += `<li>${item.quantity} ${item.accommodation.label}</li>`
    })
    const babyCount = this.booking.babies.length
    const babyPackage = this.getBabyPackage()
    if (babyCount && babyPackage) {
      label += `<li>${babyCount} ${babyPackage.label}</li>`
    }
    return label ? `<ul>${label}</ul>` : ''
  }

  getAddonsSelectionLabel() {
    let label = ''
    const selections = ['accommodationAddonSelection', 'bookingAddonSelection', 'collectiveAddonSelection', 'personalAddonSelection']
    selections.forEach((selectionName) => {
      // @ts-ignore
      this.booking[selectionName].forEach(
        (item: accommodationAddonSelectionItem | bookingAddonSelectionItem | collectiveAddonSelectionItem | personalAddonSelectionItem) => {
          label += `<li>${item.quantity} ${item.addon.label}</li>`
        }
      )
    })
    return label ? `<ul>${label}</ul>` : ''
  }

  getDiscountsSelectionLabel(t: TFunction) {
    let label = ''
    const selections = ['personalDiscountSelection', 'bargainDiscountSelection']
    selections.forEach((selectionName) => {
      // @ts-ignore
      this.booking[selectionName].forEach((item: personalDiscountSelectionItem) => {
        const text = selectionName === 'bargainDiscountSelection' ? t(item.discount.label) : item.discount.label
        label += `<li>${item.quantity} ${text}</li>`
      })
    })
    return label ? `<ul>${label}</ul>` : ''
  }

  getFurtherOptionsLabel(t: TFunction) {
    let label = ''

    // insurances
    if (this.booking.ratioSummary) {
      switch (this.getInsuranceType()) {
        case 'family':
          label +=
            this.booking.familyInsuranceSelection && !this.isNoInsuranceOption(this.booking.familyInsuranceSelection)
              ? `<li>${this.booking.familyInsuranceSelection.label}</li>`
              : ''
          break

        case 'single':
          this.booking.travellers.forEach((traveller) => {
            if (traveller.insurance && !this.isNoInsuranceOption(traveller.insurance)) {
              label += `<li>${traveller.insurance.label}</li>`
            }
          })
          break
      }

      // eSIM services
      if (this.booking.esimConfig?.enabled && this.booking.esimServiceSelection) {
        this.booking.esimServiceSelection.forEach((item) => {
          label += `<li>${item.quantity} ${item.service.label}</li>`
        })
      }

      // travel documentation
      if (this.booking.travel.pricing.isTravelDocumentationAvailable) {
        const selected = this.getTravelDocumentationOptions().find((option) => {
          return this.booking.travelDocumentationService === option.name
        }) as { label: string }
        label += `<li>${selected.label}</li>`
      }
    }

    // CO2 compensation
    if (this.isCO2CompensationAvailable() && this.booking.co2compensation) {
      label += `<li>${t('booking.co2compensation')}</li>`
    }

    return label ? `<ul>${label}</ul>` : ''
  }

  getInterTotalMainServices() {
    let total = 0

    // accommodations
    if (this.hasFamilyPackage()) {
      total += this.getFamilyPackagePrice(this.getSelectedFamilyPackage() as ratioFamilyPackageObject)
    }
    this.booking.accommodationSelection.forEach((item) => {
      const price = this.getPackagePrice(item.accommodation.ratioPackageNo)
      if (!item.accommodation.capacitySettings.partialOccupancyAllowed) {
        total += item.quantity * (price * this.getAccommodationTotalCapacity(item.accommodation.capacitySettings))
      } else {
        total += item.quantity * price
      }
    })

    // babyPackages
    const babyCount = this.booking.babies.length
    const babyPackage = this.getBabyPackage()
    if (babyCount && babyPackage) {
      const price = this.getPackagePrice(babyPackage.ratioPackageNo)
      total += babyCount * price
    }

    // addons
    const selections = ['accommodationAddonSelection', 'bookingAddonSelection', 'personalAddonSelection']
    selections.forEach((selectionName) => {
      // @ts-ignore
      this.booking[selectionName].forEach((item: accommodationAddonSelectionItem | bookingAddonSelectionItem | personalAddonSelectionItem) => {
        const price = this.getServicePrice(item.addon.ratioServiceNo)
        total += item.quantity * price
      })
    })
    this.booking.collectiveAddonSelection.forEach((item) => {
      total += this.getCollectiveAddonPrice(item.addon)
    })

    return total
  }

  getInterTotalDiscounts() {
    let total = 0
    const selections = ['personalDiscountSelection', 'bargainDiscountSelection']
    selections.forEach((selectionName) => {
      // @ts-ignore
      this.booking[selectionName].forEach((item: personalDiscountSelectionItem) => {
        const price = this.getServicePrice(item.discount.ratioServiceNo)
        total += item.quantity * price
      })
    })
    return total
  }

  getInterTotalFurtherOptions() {
    let total = 0

    if (this.booking.ratioSummary) {
      // insurances
      if (this.booking.familyInsuranceSelection) {
        total += this.getFamilyInsurancePrice(this.booking.familyInsuranceSelection.ratioServiceNo)
      } else {
        this.booking.travellers.forEach((traveller, index) => {
          if (traveller.insurance) {
            total += this.getPersonalInsurancePrice(traveller.insurance.ratioServiceNo, index)
          }
        })
      }

      // eSIM services
      if (this.booking.esimConfig?.enabled && this.booking.esimServiceSelection) {
        this.booking.esimServiceSelection.forEach((item) => {
          total += Number(item.service.price) * item.quantity
        })
      }

      // travel documentation
      if (this.booking.travel.pricing.isTravelDocumentationAvailable) {
        total += this.getServicePrice(this.getSelectedTravelDocumentationOption().ratioServiceNo, this.booking.ratioSummary.data)
      }
    }

    // CO2 compensation
    if (this.isCO2CompensationAvailable() && this.booking.co2compensation) {
      total += this.getCO2CompensationTotalPrice()
    }

    return total
  }

  getTotalPrice() {
    let total = 0

    total += this.getInterTotalMainServices()
    total += this.getInterTotalFurtherOptions()
    total += this.getInterTotalDiscounts()

    return total
  }

  isOnlinePayment() {
    return this.booking.payment.type === 'online'
  }
}

import accommodationAgeGroupedTotalCapacityObject from '@/entities/accommodationAgeGroupedTotalCapacity/interface'
import accommodationCapacitySettingsObject from '@/entities/accommodationCapacitySettings/interface'
import ratioAccommodationAddonObject from '@/entities/ratioAccommodationAddon/interface'
import ratioAccommodationObject from '@/entities/ratioAccommodation/interface'
import ratioBookingAddonObject from '@/entities/ratioBookingAddon/interface'
import ratioCollectiveAddonObject from '@/entities/ratioCollectiveAddon/interface'
import ratioFamilyPackageObject from '@/entities/ratioFamilyPackage/interface'
import ratioPersonalAddonObject from '@/entities/ratioPersonalAddon/interface'
import ratioPersonalDiscountObject from '@/entities/ratioPersonalDiscount/interface'
import ratioTravelDateObject from '@/entities/ratioTravelDate/interface'
import ratioTravelExtensionObject from '@/entities/ratioTravelExtension/interface'
import ratioTravelObject from '@/entities/ratioTravel/interface'
import travelPricingAgeGroupObject from '@/entities/travelPricingAgeGroup/interface'
import travelPricingMandatorySettingsObject from '@/entities/travelPricingMandatorySettings/interface'
import travelPricingRulesObject from '@/entities/travelPricingRules/interface'
import {
  accommodationAddonSelectionItem,
  accommodationSelectionItem,
  bookingStateBaby,
  bookingStateBillingAddress,
  bookingStateItem,
  bookingStateTraveller,
  child,
  EsimService,
} from '@/features/booking/types'
import { findLast, getAgeOnDate, getToday, isFutureDate } from '@/lib/helpers/helpers'
import LocalBooking from '@/lib/booking/LocalBooking'
import {
  sendBeginCheckoutEvent,
  sendCheckoutInitPaymentEvent,
  sendCheckoutPaymentInfoEvent,
  sendCheckoutSelectInsuranceEvent,
  sendCheckoutSelectServicesEvent,
  sendCheckoutTravellerDataEvent,
} from '@/lib/ga/gtag'
import cloneDeep from 'lodash/cloneDeep'
import bargainTierObject from '@/entities/bargainTier/interface'

// export const sanitizePricingTravelOLD = (pricingTravel: ratioTravelObject) => {
//   pricingTravel.dates = getAvailableTravelDates(pricingTravel)
//   return pricingTravel
// }
//
// const getAvailableTravelDates = (pricingTravel: ratioTravelObject) => {
//   const today = getToday(true)
//   return pricingTravel.dates.filter((date) => {
//     const startDate = new Date(new Date(date.startDate).toDateString())
//     return startDate.getTime() > today.getTime()
//   })
// }

// export const sanitizePricingTravels = (pricingTravels: ratioTravelObject[]) => {
//   if (!pricingTravels) {
//     return []
//   }
//   const sanitized = pricingTravels.map((pricingTravel) => {
//     return sanitizePricingTravel(pricingTravel)
//   })
//
//   return sanitized.filter((pricingTravel) => {
//     return pricingTravel.dates.length > 0
//   })
// }
export const sanitizePricingTravel = (pricingTravel: ratioTravelObject) => {
  pricingTravel = cloneDeep(pricingTravel)

  // remove past dates
  pricingTravel.dates = pricingTravel.dates.filter((date) => {
    return isFutureDate(date.startDate)
  })

  // remove not online bookable dates
  pricingTravel.dates = pricingTravel.dates.filter((date) => {
    // 08.09.2023: The queried field 'onlinebuchung' seems not to exist in the json -> added resilience
    // @ts-ignore
    if (date.json.onlinebuchung && date.json.onlinebuchung == 'NEIN') {
      return false
    }
    return true
  })

  // remove invalid extension dates
  pricingTravel.dates = pricingTravel.dates.filter((date) => {
    // is not extension
    if (!date.includesExtension) {
      return true
    }

    // has not all necessary members set
    if (!date.extensions.length || !date.extensionBaseDate) {
      return false
    }

    return true
  })

  // handle same-date-extensions
  let extensionDates: ratioTravelDateObject[] = []
  pricingTravel.dates
    .filter((date) => {
      return date.includesExtension && date.extensions.length > 0
    })
    .map((date) => {
      date.extensions.forEach((extension) => {
        const extensionDate = cloneDeep(date)
        extensionDate.extension = extension
        extensionDates.push(extensionDate)
      })
    })
  const defaultDates = pricingTravel.dates.filter((date) => {
    return !date.includesExtension
  })

  extensionDates = extensionDates.filter((date) => {
    // extension accommodationMapItems assignments are complete (one extensionAccommodation for each baseAccommodation)
    const missingAccommodationMappings = pricingTravel.accommodations
      .filter((accommodation) => {
        const index = date.extension.accommodationMapItems.findIndex((accommodationMapItem) => {
          return accommodationMapItem.baseAccommodation.id === accommodation.id
        })
        return index < 0
      })
      .map((accommodation) => {
        return {
          ratioServiceNo: '',
          accommodation: accommodation,
        }
      })
    return missingAccommodationMappings.length === 0
  })

  pricingTravel.dates = [...defaultDates, ...extensionDates]

  return pricingTravel
}

// export const sanitizePricingTravel = (booking: bookingStateItem) => {
//   // @ts-ignore
//   if (booking.pricingTravel && !booking.pricingTravel.isSanitized) {
//     const pricingTravel = booking.pricingTravel
//     // @ts-ignore
//     pricingTravel.isSanitized = true
//
//     // remove past dates
//     const yesterday = new Date()
//     yesterday.setDate(yesterday.getDate() + 1)
//     booking.pricingTravel.dates = booking.pricingTravel.dates.filter((date) => {
//       return new Date(date.startDate).getTime() > yesterday.getTime()
//     })
//
//     // remove invalid extension dates
//     booking.pricingTravel.dates = booking.pricingTravel.dates.filter((date) => {
//       // is not extension
//       if (!date.includesExtension) {
//         return true
//       }
//
//       // has all necessary members set
//       if (!date.extension || !date.extensionBaseDate) {
//         return false
//       }
//
//       // extension accommodationMapItems assignments are complete (one extensionAccommodation for each baseAccommodation)
//       const missingAccommodationMappings = pricingTravel.accommodations
//         .filter((accommodation) => {
//           const index = date.extension.accommodationMapItems.findIndex((accommodationMapItem) => {
//             return accommodationMapItem.baseAccommodation.id === accommodation.id
//           })
//           return index < 0
//         })
//         .map((accommodation) => {
//           return {
//             ratioServiceNo: '',
//             accommodation: accommodation,
//           }
//         })
//       return missingAccommodationMappings.length === 0
//     })
//   }
// }

export const applyTravelDate = (booking: bookingStateItem, travelDate?: ratioTravelDateObject) => {
  // set date
  booking.travelDate = travelDate

  determineAndSetAvailabilityStatus(booking)

  // set bus stops
  setFixedBusStops(booking, travelDate)
}

export const determineAndSetAvailabilityStatus = (booking: bookingStateItem) => {
  // check travelDate
  if (booking?.travelDate?.availabilityStatus) {
    booking.availabilityStatus = booking.travelDate.availabilityStatus
    return
  }

  // check travel
  if (booking?.travel?.availabilityStatus) {
    booking.availabilityStatus = booking.travel.availabilityStatus
    return
  }

  // set default
  if (booking.defaultAvailabilityStatus) {
    booking.availabilityStatus = booking.defaultAvailabilityStatus
  }
  return
}

export const orderTravellers = (travellers: bookingStateTraveller[], ageGroups: travelPricingAgeGroupObject[]) => {
  if (travellers.length <= 1 || ageGroups.length <= 1) {
    return travellers
  } else {
    let orderedTravellers: bookingStateTraveller[] = []
    ageGroups.forEach((ageGroup) => {
      orderedTravellers.push(
        ...travellers.filter((traveller) => {
          return traveller.ageGroup.id === ageGroup.id
        })
      )
    })
    return orderedTravellers
  }
}

export const buildTravellersFromChildrenData = (
  children: child[],
  ageGroups: travelPricingAgeGroupObject[],
  date: ratioTravelDateObject
): bookingStateTraveller[] => {
  return (
    children
      // filter out children without birthday
      .filter((child) => {
        return !!child.birthday
      })
      // build traveller objects
      .map((child) => {
        return buildTraveller(99, {
          ageGroup: getAgeGroupByBirthday(new Date(child.birthday), ageGroups, new Date(date.endDate), 'MINOR'),
          birthday: child.birthday,
          ageOnStartDate: getAgeOnDate(new Date(child.birthday), new Date(date.startDate)),
          ageOnEndDate: getAgeOnDate(new Date(child.birthday), new Date(date.endDate)),
        })
      })
  )
}

const getBabyPackage = (accommodations: ratioAccommodationObject[]) => {
  return accommodations.find((accommodation) => {
    return accommodation.isBabyPackage
  })
}

export const buildBabyTravellers = (babies: child[], accommodations: ratioAccommodationObject[]): bookingStateBaby[] => {
  const babyPackage = getBabyPackage(accommodations)
  const packageNo = babyPackage ? babyPackage.ratioPackageNo : ''
  return babies.map((baby) => {
    return {
      ...baby,
      salutation: 'MR',
      ratioPackageNo: packageNo,
      passportData: getPassportDataDefaultObject(),
    } as bookingStateBaby
  })
}

const getAgeGroupByBirthday = (
  birthday: Date,
  ageGroups: travelPricingAgeGroupObject[],
  travelEndDate: Date,
  ageGroupTypeFilter?: undefined | 'MINOR'
): travelPricingAgeGroupObject => {
  const ageOnTravelEnd = getAgeOnDate(birthday, travelEndDate)
  if (ageGroupTypeFilter) {
    ageGroups = ageGroups.filter((ageGroup) => {
      return ageGroup.type === ageGroupTypeFilter
    })
  }
  return ageGroups.find((ageGroup) => {
    return ageGroup.minAge <= ageOnTravelEnd && ageGroup.maxAge >= ageOnTravelEnd
  }) as travelPricingAgeGroupObject
}

export const removeTravellerServices = (booking: bookingStateItem) => {
  booking.travellers = booking.travellers.map((traveller, index: number) => {
    const newTraveller = { ...traveller }
    delete newTraveller.accommodation
    delete newTraveller.familyPackage
    newTraveller.accommodationAddons = []
    newTraveller.personalAddons = []
    newTraveller.bookingAddons = []
    newTraveller.collectiveAddons = []
    newTraveller.extensions = []
    newTraveller.personalDiscounts = []
    return buildTraveller(index, newTraveller)

    // return buildTraveller(index, {
    //   firstName: traveller.firstName,
    //   lastName: traveller.lastName,
    //   ageGroup: traveller.ageGroup,
    //   birthday: traveller.birthday ? traveller.birthday : '',
    // })
  })
  booking.accommodationSelection = []
  booking.accommodationAddonSelection = []
  booking.personalAddonSelection = []
  booking.collectiveAddonSelection = []
  booking.bookingAddonSelection = []
  booking.personalDiscountSelection = []
  booking.familyInsuranceSelection = undefined
}

export const removeChildTravellers = (booking: bookingStateItem) => {
  booking.travellers = booking.travellers.filter((traveller) => {
    return traveller.ageGroup.type === 'ADULT'
  })
}

export const removeBabyTravellers = (booking: bookingStateItem) => {
  booking.babies = []
}

export const setTravellerData = (traveller: bookingStateTraveller, data: { [x: string]: any }) => {
  if (data.sameAddressAsTravellerIndex === undefined) {
    traveller.sameAddressAsTravellerIndex = undefined
  }

  for (let key in data) {
    // @ts-ignore
    traveller[key] = data[key]
  }
}

// check min age for billing address
export const handleBillingAddressMinAge = (booking: bookingStateItem) => {
  const minAgeYears = Number(process.env.NEXT_PUBLIC_BOOKING_BILLING_ADDRESS_MIN_AGE_YEARS)
  // calculate today - minAgeYears
  const minAgeDate = new Date()
  minAgeDate.setFullYear(minAgeDate.getFullYear() - minAgeYears)

  // check if billingAddress-traveller is younger than minAgeYears
  booking.travellers.map((traveller) => {
    const travellerBirthdayDate = new Date(traveller.birthday)
    if (traveller.isBillingAddress && travellerBirthdayDate.getTime() > minAgeDate.getTime()) {
      // force separate billing address
      booking.billingAddressForcedDueToMinorAge = true
      traveller.isBillingAddress = false
      traveller.triggeredForceBillingAddress = true
    } else if (traveller.isBillingAddress) {
      booking.billingAddressForcedDueToMinorAge = false
      traveller.triggeredForceBillingAddress = false
    }

    // reset triggeredForceBillingAddress if birthday changed
    if (traveller.triggeredForceBillingAddress && travellerBirthdayDate.getTime() <= minAgeDate.getTime()) {
      booking.billingAddressForcedDueToMinorAge = false
      traveller.triggeredForceBillingAddress = false
    }
  })
}

export const setBabyData = (baby: bookingStateBaby, data: { [x: string]: any }) => {
  for (let key in data) {
    // @ts-ignore
    baby[key] = data[key]
  }
}

export const setBillingAddressData = (booking: bookingStateItem, data: bookingStateBillingAddress) => {
  for (let key in data) {
    // @ts-ignore
    booking.billingAddress[key] = data[key]
  }
}

export const unsetFamilyPackage = (booking: bookingStateItem) => {
  booking.travellers.map((traveller) => {
    traveller.familyPackage = undefined
    if (traveller.isFamilyPackageMember) {
      traveller.accommodation = undefined
      traveller.isFamilyPackageMember = undefined
    }
  })
}

export const unsetTravellerAccommodations = (booking: bookingStateItem) => {
  booking.travellers.map((traveller) => {
    traveller.accommodation = undefined
    traveller.familyPackage = undefined
  })
}

export const travellerHasAccommodation = (traveller: bookingStateTraveller) => {
  return !!traveller.accommodation
}

export const getTravellerAgeGroupType = (traveller: bookingStateTraveller) => {
  return traveller.ageGroup.type
}

export const getAccommodationCapacityCount = (capacitySettings: accommodationCapacitySettingsObject) => {
  const groupedCapacities = getGroupedAccommodationCapacityCounts(capacitySettings)
  return groupedCapacities.adults + groupedCapacities.minors
}

export const getGroupedAccommodationCapacityCounts = (capacitySettings: accommodationCapacitySettingsObject) => {
  let capacities = {
    adults: 0,
    minors: 0,
  }
  if (!capacitySettings.rangesAllowed) {
    // simply count maxCapacity of all groups
    capacitySettings.ageGroupCapacities.map((ageGroupCapacity) => {
      switch (ageGroupCapacity.ageGroup.type) {
        case 'ADULT':
          capacities.adults += ageGroupCapacity.maxCapacity
          break
        case 'MINOR':
          capacities.minors += ageGroupCapacity.maxCapacity
          break
      }
    })
  } else {
    // if has one ADULT group -> count towards 'adults'
    capacitySettings.ageGroupedTotalCapacities.map((ageGroupedTotalCapacity: accommodationAgeGroupedTotalCapacityObject) => {
      const hasAdultAgeGroups = !!ageGroupedTotalCapacity.ageGroups.filter((ageGroup) => {
        return ageGroup.type === 'ADULT'
      }).length
      if (hasAdultAgeGroups) {
        capacities.adults += ageGroupedTotalCapacity.capacity
      } else {
        capacities.minors += ageGroupedTotalCapacity.capacity
      }
    })
  }
  return capacities
}

export const getAccommodationAgeGroupType = (capacitySettings: accommodationCapacitySettingsObject) => {
  const capacity = getGroupedAccommodationCapacityCounts(capacitySettings)
  return capacity.adults ? 'ADULT' : 'MINOR'
}

export const isAccommodationApplicableToTraveller = (accommodation: ratioAccommodationObject, traveller: bookingStateTraveller) => {
  return !!accommodation.capacitySettings.ageGroupCapacities.find((ageGroupCapacity) => {
    // must have capacity for travellers ageGroup
    return ageGroupCapacity.ageGroup.id === traveller.ageGroup.id && ageGroupCapacity.maxCapacity > 0
  })
}

export const sanitizeFamilyPackageAddons = (booking: bookingStateItem, familyPackage: ratioFamilyPackageObject | null) => {
  if (!familyPackage) {
    // remove addon
    booking.accommodationAddonSelection
      // filter affected
      .filter((item) => {
        return item.type === 'familyPackage'
      })
      // remove by index
      .forEach(() => {
        const index = booking.accommodationAddonSelection.findIndex((item) => {
          return item.type === 'familyPackage'
        })
        booking.accommodationAddonSelection.splice(index, 1)
      })
  }
}

export const sanitizeAccommodationAddons = (booking: bookingStateItem) => {
  // check each addon
  booking.accommodationAddonSelection.forEach((addonItem: accommodationAddonSelectionItem, index: number) => {
    // get relevant accommodation
    const accommodationSelectionItem = booking.accommodationSelection.find((accommodationItem: accommodationSelectionItem) => {
      return addonItem.type === 'accommodation' && accommodationItem.accommodation.id === addonItem.typeId
    })

    if (!accommodationSelectionItem) {
      // if not found: remove addon
      booking.accommodationAddonSelection.splice(index, 1)
    } else {
      // sanitize quantity
      if (accommodationSelectionItem.quantity < addonItem.quantity) {
        addonItem.quantity = accommodationSelectionItem.quantity
      }
    }
  })
}

export const applyAccommodationAddonSelection = (booking: bookingStateItem) => {
  // remove current addon assignments from traveller
  booking.travellers[0].accommodationAddons = []

  // add quantity of each item to first traveller
  booking.accommodationAddonSelection.map((item) => {
    let openQuantity = item.quantity
    for (let i = 0; i < openQuantity; i++) {
      booking.travellers[0].accommodationAddons.push(item.addon)
    }
  })
}

export const applyPersonalAddonSelection = (booking: bookingStateItem) => {
  // remove current addon assignments from travellers
  booking.travellers.map((traveller) => {
    traveller.personalAddons = []
  })

  // add CO2 compensation to all travellers
  if (booking.co2compensation) {
    const addon = {
      ratioServiceNo: process.env.NEXT_PUBLIC_BOOKING_CO2_COMPENSATION_SERVICE,
      name: 'CO2-Kompensation',
      label: 'CO2-Kompensation',
    }
    booking.travellers.map((traveller) => {
      traveller.personalAddons.push(addon as ratioPersonalAddonObject)
    })
  }

  // add quantity of each item to applicable travellers
  booking.personalAddonSelection.map((item) => {
    let openQuantity = item.quantity
    const travellers = getPersonalAddonApplicableTravellers(booking, item.addon)
    travellers.forEach((traveller) => {
      if (openQuantity > 0 && !item.addon.mandatorySettings.isMandatory) {
        traveller.personalAddons.push(item.addon)
        openQuantity--
      }
    })
  })
}

export const applyBookingAddonSelection = (booking: bookingStateItem) => {
  // remove current addon assignments from traveller
  booking.travellers[0].bookingAddons = []

  // add quantity of each item to first traveller
  booking.bookingAddonSelection.map((item) => {
    let openQuantity = item.quantity
    for (let i = 0; i < openQuantity; i++) {
      booking.travellers[0].bookingAddons.push(item.addon)
    }
  })
}

export const applyCollectiveAddonSelection = (booking: bookingStateItem) => {
  // remove current addon assignments from travellers
  booking.travellers.map((traveller) => {
    traveller.collectiveAddons = []
  })

  // add addon to all travellers
  booking.collectiveAddonSelection.map((item) => {
    booking.travellers.map((traveller) => {
      traveller.collectiveAddons.push(item.addon)
    })
  })
}

const getPersonalAddonApplicableTravellers = (booking: bookingStateItem, addon: ratioPersonalAddonObject) => {
  let applicableTravellers: bookingStateTraveller[] = []
  if (!addon.mandatorySettings.isMandatory && addon.mandatorySettings.useRules) {
    applicableTravellers = booking.travellers.filter((traveller) => {
      return doMandatorySettingsApply(addon.mandatorySettings, traveller, booking.travelDate as ratioTravelDateObject)
    })
  } else {
    addon.allowedAgeGroups.map((allowedAgeGroup) => {
      applicableTravellers = [...applicableTravellers, ...getTravellersByAgeGroup(booking, allowedAgeGroup)]
    })
  }
  // remove travellers where this service is already assigned
  //*** currently obsolete, because selection is always reset before execution of this function
  // applicableTravellers.map((traveller, index: number) => {
  //   if (
  //     traveller.personalAddons.find((assignedAddon) => {
  //       return assignedAddon.id === addon.id
  //     })
  //   ) {
  //     applicableTravellers.splice(index, 1)
  //   }
  // })
  return applicableTravellers
}

const getTravellersByAgeGroup = (booking: bookingStateItem, ageGroup: travelPricingAgeGroupObject) => {
  return booking.travellers.filter((traveller) => {
    return traveller.ageGroup.id === ageGroup.id
  })
}

export const applyMandatoryAddons = (booking: bookingStateItem) => {
  if (booking.pricingTravel) {
    // personal addons
    booking.pricingTravel.personalAddons
      .filter((addon) => {
        return addon.mandatorySettings.isMandatory
      })
      .map((addon) => {
        applyMandatoryPersonalAddon(booking, addon)
      })

    // collective addons
    booking.pricingTravel.collectiveAddons
      .filter((addon) => {
        return addon.mandatorySettings.isMandatory
      })
      .map((addon) => {
        applyMandatoryCollectiveAddon(booking, addon)
      })

    // booking addons
    booking.pricingTravel.bookingAddons
      .filter((addon) => {
        return addon.mandatorySettings.isMandatory
      })
      .map((addon) => {
        applyMandatoryBookingAddon(booking, addon)
      })

    // accommodation addons
    booking.pricingTravel.accommodationAddons
      .filter((addon) => {
        return addon.mandatorySettings.isMandatory
      })
      .map((addon) => {
        applyMandatoryAccommodationAddon(booking, addon)
      })
  }
}

const applyMandatoryPersonalAddon = (booking: bookingStateItem, addon: ratioPersonalAddonObject) => {
  // count quantity to update "booking.personalAddonSelection"
  let quantity = 0

  const allowedAgeGroupIds = addon.allowedAgeGroups.map((ageGroup) => {
    return ageGroup.id
  })

  booking.travellers
    // only apply for allowed age groups
    .filter((traveller) => {
      return allowedAgeGroupIds.includes(traveller.ageGroup.id)
    })

    // check rules
    .forEach((traveller) => {
      const shouldApply = doMandatorySettingsApply(addon.mandatorySettings, traveller, booking.travelDate as ratioTravelDateObject)
      // remove current
      removeTravellerPersonalAddon(traveller, addon)

      // add
      if (shouldApply) {
        traveller.personalAddons.push(addon)
        quantity++
      }
    })

  // update personalAddonsSelection
  // remove if exists
  const itemIndex = booking.personalAddonSelection.findIndex((item) => {
    return item.addon.id === addon.id
  })
  if (itemIndex >= 0) {
    booking.personalAddonSelection.splice(itemIndex, 1)
  }
  // add new
  if (quantity) {
    booking.personalAddonSelection.push({
      addon: addon,
      quantity: quantity,
      mandatory: true,
    })
  }
}

const applyMandatoryCollectiveAddon = (booking: bookingStateItem, addon: ratioCollectiveAddonObject) => {
  // count quantity to update "booking.personalAddonSelection"
  let shouldApply = false

  // remove current
  booking.collectiveAddonSelection = booking.collectiveAddonSelection.filter((item) => {
    return item.addon.id !== addon.id
  })

  // check rules
  booking.travellers.forEach((traveller) => {
    if (doMandatorySettingsApply(addon.mandatorySettings, traveller, booking.travelDate as ratioTravelDateObject)) {
      shouldApply = true
    }
  })

  // add
  if (shouldApply) {
    booking.collectiveAddonSelection.push({
      addon: addon,
      quantity: 1,
      mandatory: true,
    })
  }

  // update travellers
  applyCollectiveAddonSelection(booking)
}

const applyMandatoryBookingAddon = (booking: bookingStateItem, addon: ratioBookingAddonObject) => {
  // count quantity to update "booking.personalAddonSelection"
  let shouldApply = false

  // remove current
  booking.bookingAddonSelection = booking.bookingAddonSelection.filter((item) => {
    return item.addon.id !== addon.id
  })

  // check rules
  booking.travellers.forEach((traveller) => {
    if (doMandatorySettingsApply(addon.mandatorySettings, traveller, booking.travelDate as ratioTravelDateObject)) {
      shouldApply = true
    }
  })

  // add
  if (shouldApply) {
    booking.bookingAddonSelection.push({
      addon: addon,
      quantity: 1,
      mandatory: true,
    })
  }

  // update travellers
  applyBookingAddonSelection(booking)
}

// currently only "mandatory" is supported, rules are not considered
const applyMandatoryAccommodationAddon = (booking: bookingStateItem, addon: ratioAccommodationAddonObject) => {
  // add function
  const add = (type: 'accommodation' | 'familyPackage', typeId: number, quantity: number) => {
    booking.accommodationAddonSelection.push({
      type: type,
      typeId: typeId,
      addon: addon,
      quantity: quantity,
      mandatory: true,
    })
  }

  // remove current
  booking.accommodationAddonSelection = booking.accommodationAddonSelection.filter((item) => {
    return item.addon.id !== addon.id
  })

  // add addon for each selected accommodation where this addon is available
  booking.accommodationSelection.forEach((item) => {
    if (item.accommodation.addons.find((itemAddon) => itemAddon.id === addon.id)) {
      add('accommodation', item.accommodation.id as number, item.quantity)
    }
  })

  // add addon for the familyPackage if this addon is available
  const familyPackage = booking.travellers[0].familyPackage
  if (familyPackage && familyPackage.addons.find((itemAddon) => itemAddon.id === addon.id)) {
    add('familyPackage', familyPackage.id as number, 1)
  }

  // update travellers
  applyAccommodationAddonSelection(booking)
}

export const sanitizeAddonsWithRules = (booking: bookingStateItem) => {
  if (booking.pricingTravel) {
    booking.pricingTravel.personalAddons
      // get all non-mandatory personal addons with activated rules
      .filter((addon) => {
        return !addon.mandatorySettings.isMandatory && addon.mandatorySettings.useRules
      })
      .map((addon) => {
        sanitizePersonalAddonWithRules(booking, addon)
      })

    booking.pricingTravel.collectiveAddons
      // get all non-mandatory collective addons with activated rules
      .filter((addon) => {
        return !addon.mandatorySettings.isMandatory && addon.mandatorySettings.useRules
      })
      .map((addon) => {
        sanitizeCollectiveAddonWithRules(booking, addon)
      })
  }
}

const sanitizePersonalAddonWithRules = (booking: bookingStateItem, addon: ratioPersonalAddonObject) => {
  const manager = new LocalBooking(booking)
  const maxQuantity = manager.getPersonalAddonMaxQuantity(addon)

  // check if addon is applied to any traveller
  const itemIndex = booking.personalAddonSelection.findIndex((item) => {
    return item.addon.id === addon.id
  })
  if (itemIndex >= 0) {
    const item = booking.personalAddonSelection[itemIndex]
    // if current addon quantity is higher than maxQuantity
    if (item.quantity > maxQuantity) {
      // update selection and travellers
      booking.personalAddonSelection[itemIndex].quantity = maxQuantity
      applyPersonalAddonSelection(booking)
    }
  }
}

const sanitizeCollectiveAddonWithRules = (booking: bookingStateItem, addon: ratioCollectiveAddonObject) => {
  const manager = new LocalBooking(booking)
  const maxQuantity = manager.getCollectiveAddonMaxQuantity(addon)

  // check if addon is applied
  const itemIndex = booking.collectiveAddonSelection.findIndex((item) => {
    return item.addon.id === addon.id
  })
  if (itemIndex >= 0) {
    const item = booking.collectiveAddonSelection[itemIndex]
    // if current addon quantity is higher than maxQuantity
    if (item.quantity > maxQuantity) {
      // update selection and travellers
      booking.collectiveAddonSelection[itemIndex].quantity = maxQuantity
      applyCollectiveAddonSelection(booking)
    }
  }
}

const removeTravellerPersonalAddon = (traveller: bookingStateTraveller, addon: ratioPersonalAddonObject) => {
  const addonIndex = traveller.personalAddons.findIndex((currentAddon) => {
    return currentAddon.id === addon.id
  })
  if (addonIndex >= 0) {
    traveller.personalAddons.splice(addonIndex, 1)
  }
}

export const doMandatorySettingsApply = (
  mandatorySettings: travelPricingMandatorySettingsObject,
  traveller: bookingStateTraveller,
  bookingTravelDate: ratioTravelDateObject
) => {
  if (!mandatorySettings.useRules) {
    return true
  } else {
    // check rules
    return doMandatoryRulesApply(mandatorySettings.rules, traveller, bookingTravelDate)
  }
}

export const doMandatoryRulesApply = (
  rules: travelPricingRulesObject,
  traveller: bookingStateTraveller,
  bookingTravelDate: ratioTravelDateObject
) => {
  let passedDateRangeCheck = false
  let passedAccommodationCheck = false
  let passedAgeGroupCheck: boolean
  let passedTravelDateCheck: boolean

  // 1. date range
  if (rules.checkDateRange) {
    const today = getToday().getTime()
    const startTime = new Date(new Date(rules.startDate).toDateString()).getTime()
    const endTime = new Date(new Date(rules.endDate).toDateString()).getTime()
    if (today >= startTime && today <= endTime) {
      passedDateRangeCheck = true
    }
  } else {
    passedDateRangeCheck = true
  }

  // 2. accommodations
  if (rules.checkAccommodationTypes) {
    const accommodationIds = rules.accommodations.map((accommodation) => {
      return accommodation.id
    })
    if (traveller.accommodation && accommodationIds.includes(traveller.accommodation.id)) {
      passedAccommodationCheck = true
    }
  } else {
    passedAccommodationCheck = true
  }

  // 3. ageGroups
  if (rules.checkAgeGroups) {
    const ageGroupIds = rules.ageGroups.map((ageGroup) => {
      return ageGroup.id
    })
    passedAgeGroupCheck = ageGroupIds.includes(traveller.ageGroup.id) && travellerPassesAgeFilter(traveller, rules)
  } else {
    passedAgeGroupCheck = true
  }

  // 4. travelDate
  if (rules.checkTravelDate) {
    passedTravelDateCheck = !!rules.travelDates.find((travelDate) => {
      return travelDate.id == bookingTravelDate.id
    })
  } else {
    passedTravelDateCheck = true
  }

  return passedDateRangeCheck && passedAccommodationCheck && passedAgeGroupCheck && passedTravelDateCheck
}

const travellerPassesAgeFilter = (traveller: bookingStateTraveller, rules: travelPricingRulesObject) => {
  if (!rules.useAgeFilter) {
    return true
  }

  // ageFilter is only applicable for child travellers
  if (!traveller.ageOnStartDate) {
    return false
  }

  return traveller.ageOnStartDate >= rules.ageFilter.minAge && traveller.ageOnStartDate <= rules.ageFilter.maxAge
}
export const applyMandatoryPersonalDiscounts = (booking: bookingStateItem) => {
  if (booking.pricingTravel) {
    booking.pricingTravel.personalDiscounts
      .filter((discount) => {
        return discount.mandatorySettings.isMandatory
      })
      .map((discount) => {
        applyMandatoryPersonalDiscount(booking, discount)
      })
  }
}

export const sanitizeDiscountsWithRules = (booking: bookingStateItem) => {
  if (booking.pricingTravel) {
    booking.pricingTravel.personalDiscounts
      .filter((discount) => {
        return !discount.mandatorySettings.isMandatory && discount.mandatorySettings.useRules
      })
      .map((discount) => {
        sanitizePersonalDiscountWithRules(booking, discount)
      })
  }
}

const sanitizePersonalDiscountWithRules = (booking: bookingStateItem, discount: ratioPersonalDiscountObject) => {
  const manager = new LocalBooking(booking)
  const maxQuantity = manager.getPersonalDiscountMaxQuantity(discount)

  // check if discount is applied to any traveller
  const itemIndex = booking.personalDiscountSelection.findIndex((item) => {
    return item.discount.id === discount.id
  })
  if (itemIndex >= 0) {
    const item = booking.personalDiscountSelection[itemIndex]
    // if current discount quantity is higher than maxQuantity
    if (item.quantity > maxQuantity) {
      // update selection and travellers
      booking.personalDiscountSelection[itemIndex].quantity = maxQuantity
      applyPersonalDiscountSelection(booking)
    }
  }
}

export const applyPersonalDiscountSelection = (booking: bookingStateItem) => {
  // remove current discount assignments from travellers
  booking.travellers.map((traveller) => {
    traveller.personalDiscounts = []
  })

  // add quantity of each item to applicable travellers
  booking.personalDiscountSelection.map((item) => {
    let openQuantity = item.quantity
    const travellers = getPersonalDiscountApplicableTravellers(booking, item.discount)
    travellers.forEach((traveller) => {
      if (openQuantity > 0 && !item.discount.mandatorySettings.isMandatory) {
        traveller.personalDiscounts.push(item.discount)
        openQuantity--
      }
    })
  })
}

const getPersonalDiscountApplicableTravellers = (booking: bookingStateItem, discount: ratioPersonalDiscountObject) => {
  let applicableTravellers: bookingStateTraveller[]
  if (!discount.mandatorySettings.isMandatory && discount.mandatorySettings.useRules) {
    applicableTravellers = booking.travellers.filter((traveller) => {
      return doMandatoryRulesApply(discount.mandatorySettings.rules, traveller, booking.travelDate as ratioTravelDateObject)
    })
  } else {
    applicableTravellers = booking.travellers
  }
  // remove travellers where this service is already assigned
  //*** currently obsolete, because selection is always reset before execution of this function
  // applicableTravellers.map((traveller, index: number) => {
  //   if (
  //     traveller.personalDiscounts.find((assignedDiscount) => {
  //       return assignedDiscount.id === discount.id
  //     })
  //   ) {
  //     applicableTravellers.splice(index, 1)
  //   }
  // })
  return applicableTravellers
}

const applyMandatoryPersonalDiscount = (booking: bookingStateItem, discount: ratioPersonalDiscountObject) => {
  // count quantity to update "booking.personalDiscountSelection"
  let quantity = 0

  booking.travellers
    // check rules
    .forEach((traveller) => {
      // console.log('discount', discount.label)
      let shouldApply = doMandatorySettingsApply(discount.mandatorySettings, traveller, booking.travelDate as ratioTravelDateObject)
      // if (discount.mandatorySettings.useRules) {
      //   shouldApply = doMandatoryRulesApply(discount.mandatorySettings.rules, traveller, booking.travelDate as ratioTravelDateObject)
      // }

      // remove current
      removeTravellerPersonalDiscount(traveller, discount)

      // add
      if (shouldApply) {
        traveller.personalDiscounts.push(discount)
        quantity++
      }
    })

  // update personalDiscountSelection
  // remove if exists
  const itemIndex = booking.personalDiscountSelection.findIndex((item) => {
    return item.discount.id === discount.id
  })
  if (itemIndex >= 0) {
    booking.personalDiscountSelection.splice(itemIndex, 1)
  }
  // add new
  if (quantity) {
    booking.personalDiscountSelection.push({
      discount: discount,
      quantity: quantity,
    })
  }
}

const removeTravellerPersonalDiscount = (traveller: bookingStateTraveller, discount: ratioPersonalDiscountObject) => {
  const discountIndex = traveller.personalDiscounts.findIndex((currentDiscount) => {
    return currentDiscount.id === discount.id
  })
  if (discountIndex >= 0) {
    traveller.personalDiscounts.splice(discountIndex, 1)
  }
}

export const applyBargainDiscount = (booking: bookingStateItem) => {
  if (booking.travel?.pricing.bargainSettings && isBargainDiscountActive(booking)) {
    const amount = calculateBargainDiscountAmountByTier(booking, booking.travel.pricing.bargainSettings.bargainTier)
    const prefix = booking.bargainConfig?.serviceCodePrefix || 'MISSING_BARGAIN_SERVICE_CODE_PREFIX'
    if (amount > 0) {
      const serviceNo = getBargainDiscountServiceNoByCode(booking, prefix + booking.travel.pricing.bargainSettings.bargainTier.discount)
      if (serviceNo) {
        booking.bargainDiscountSelection = [
          {
            discount: {
              ratioServiceNo: serviceNo,
              serviceCode: prefix + booking.travel.pricing.bargainSettings.bargainTier.discount,
              label: 'booking.bargainDiscount',
            },
            quantity: booking.travellers.length,
          },
        ]
      }
    }
  }
}

const isBargainDiscountActive = (booking: bookingStateItem): boolean => {
  if (booking.bargainConfig?.active && booking.travelDate && booking.travel?.pricing.bargainSettings.active) {
    const bargainSettings = booking.travel.pricing.bargainSettings
    const daysUntilStart = getDaysUntilStart(booking.travelDate)
    if (bargainSettings.untilDaysBeforeStart <= daysUntilStart + 1) {
      return true
    }
  }
  return false
}

const calculateBargainDiscountAmountByTier = (booking: bookingStateItem, tier: bargainTierObject): number => {
  return tier.discount
  // Old solution: one discount service per booking, with priceCap
  /*
  let amount = 0
  const priceCap = booking.bargainConfig?.priceCap
  booking.travellers.forEach(() => {
    amount += tier.discount
  })
  if (priceCap && amount > priceCap) {
    return priceCap
  }
  return amount
   */
}

const getBargainDiscountServiceNoByCode = (booking: bookingStateItem, code: string): any => {
  // @ts-ignore
  const json = booking.travelDate.json
  // @ts-ignore
  const item = json.t_reiseleistungen.data.find((item: any) => {
    return item.code == code
  })
  if (item) {
    return item.leistungnr
  }
  return false
  // return {
  //   nr: item.nr,
  //   code: item.code,
  //   leistungnr: item.leistungnr,
  //   derived_preis: item.derived_preis,
  // }
}

export const updateEsimServiceSelection = (booking: bookingStateItem, service: EsimService, quantity: number) => {
  if (!booking.esimServiceSelection) {
    booking.esimServiceSelection = []
  }
  const selectionIndex = booking.esimServiceSelection.findIndex((item) => {
    return item.service.serviceNo == service.serviceNo
  })
  // if service already exists
  if (selectionIndex >= 0) {
    if (quantity == 0) {
      // remove
      booking.esimServiceSelection.splice(selectionIndex, 1)
    } else {
      // update quantity
      booking.esimServiceSelection[selectionIndex].quantity = quantity
    }
  } else {
    // add new service to selection
    booking.esimServiceSelection.push({
      quantity: quantity,
      service: service,
    })
  }
}

const getDaysUntilStart = (travelDate: ratioTravelDateObject) => {
  const today = getToday()
  return Math.floor((new Date(new Date(travelDate.startDate).toDateString()).getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) + 1
}
interface buildTravellerProps {
  salutation?: 'MR' | 'MRS'
  firstName?: string
  lastName?: string
  email?: string
  nationality?: string
  birthday?: string
  address?: string
  addressAddition?: string
  zip?: string
  city?: string
  country?: string
  phone?: string
  sameAddressAsTravellerIndex?: number
  ageGroup: travelPricingAgeGroupObject
  ageOnStartDate?: number
  ageOnEndDate?: number
  accommodation?: ratioAccommodationObject
  familyPackage?: ratioFamilyPackageObject
  accommodationAddons?: ratioAccommodationAddonObject[]
  personalAddons?: ratioPersonalAddonObject[]
  bookingAddons?: ratioBookingAddonObject[]
  collectiveAddons?: ratioCollectiveAddonObject[]
  extensions?: ratioTravelExtensionObject[]
  personalDiscounts?: ratioPersonalDiscountObject[]
}

export const buildTraveller = (index: number, data: buildTravellerProps): bookingStateTraveller => {
  return {
    address: data.address ? data.address : '',
    addressAddition: data.addressAddition ? data.addressAddition : '',
    city: data.city ? data.city : '',
    country: data.country ? data.country : '',
    email: data.email ? data.email : '',
    isBillingAddress: index === 0,
    nationality: data.nationality ? data.nationality : '',
    phone: data.phone ? data.phone : '',
    sameAddressAsTravellerIndex: data.sameAddressAsTravellerIndex ? data.sameAddressAsTravellerIndex : undefined,
    zip: data.zip ? data.zip : '',
    salutation: data.salutation ? data.salutation : 'MR',
    firstName: data.firstName ? data.firstName : '',
    lastName: data.lastName ? data.lastName : '',
    birthday: data.birthday ? data.birthday : '',
    ageGroup: data.ageGroup,
    ageOnStartDate: data.ageOnStartDate ? data.ageOnStartDate : undefined,
    ageOnEndDate: data.ageOnEndDate ? data.ageOnEndDate : undefined,
    accommodation: data.accommodation ? data.accommodation : undefined,
    familyPackage: data.familyPackage ? data.familyPackage : undefined,
    accommodationAddons: data.accommodationAddons ? data.accommodationAddons : [],
    personalAddons: data.personalAddons ? data.personalAddons : [],
    bookingAddons: data.bookingAddons ? data.bookingAddons : [],
    collectiveAddons: data.collectiveAddons ? data.collectiveAddons : [],
    extensions: data.extensions ? data.extensions : [],
    personalDiscounts: data.personalDiscounts ? data.personalDiscounts : [],
    passportData: getPassportDataDefaultObject(),
  }
}

const getPassportDataDefaultObject = () => {
  return {
    type: 'PASSPORT',
    number: '',
    birthPlace: '',
    issuePlace: '',
    issueDate: '',
    validUntil: '',
  }
}

export const buildTravellers = (data: buildTravellerProps[]): bookingStateTraveller[] => {
  return data.map((travellerData, index: number) => {
    const traveller = buildTraveller(index, travellerData)
    if (index === 0) {
      traveller.isBillingAddress = true
    }
    if (index > 0) {
      traveller.sameAddressAsTravellerIndex = 0
    }
    return traveller
  })
}

// extensions

export const upgradeToExtensionDate = (booking: bookingStateItem, extensionDate: ratioTravelDateObject) => {
  booking.isUpgradedToExtension = true
  booking.extensionDowngradeDate = booking.travelDate
  booking.travelDate = extensionDate

  // reset contingents
  booking.contingents = undefined
  booking.contingentTimestamp = undefined
}

export const downgradeFromExtensionDate = (booking: bookingStateItem) => {
  booking.isUpgradedToExtension = false
  booking.travelDate = booking.extensionDowngradeDate
  booking.extensionDowngradeDate = undefined

  // reset contingents
  booking.contingents = undefined
  booking.contingentTimestamp = undefined
}

// set outbound DropOff & return boarding
export const setFixedBusStops = (booking: bookingStateItem, travelDate?: ratioTravelDateObject) => {
  if (!travelDate) {
    // reset
    booking.busStops = undefined
  } else {
    const json: any = travelDate.json

    // outbound dropOff
    let outboundStop: { nr: string } | undefined
    if (json.t_knotens_hin && json.t_knotens_hin.data.length) {
      const outboundDestinationNodeNo = json.t_knotens_hin.data[json.t_knotens_hin.data.length - 1].nr
      outboundStop = findLast(json.t_knotenhalte_hin.data, (stopItem: any) => {
        return stopItem.knoten === outboundDestinationNodeNo
      })
    }

    // return boarding
    let returnStop: { nr: string } | undefined
    if (json.t_knotens_rueck && json.t_knotens_rueck.data.length) {
      const returnOriginNodeNo = json.t_knotens_rueck.data[0].nr
      returnStop = findLast(json.t_knotenhalte_rueck.data, (stopItem: any) => {
        return stopItem.knoten === returnOriginNodeNo
      })
    }

    booking.busStops = {
      outboundDropOffStopNo: outboundStop ? outboundStop.nr : '',
      returnBoardingStopNo: returnStop ? returnStop.nr : '',
    }
  }
}

export const sanitizeInsuranceSelection = (booking: bookingStateItem) => {
  const manager = new LocalBooking(booking)
  const personalInsurances = manager.getPersonalInsurances()
  booking.travellers.forEach((traveller) => {
    if (traveller.insurance) {
      const available = !!personalInsurances.filter((insurance) => {
        return insurance.ratioServiceNo === traveller.insurance?.ratioServiceNo
      }).length
      if (!available) {
        traveller.insurance = undefined
      }
    }
  })
}

export const resetBooking = (booking: bookingStateItem) => {
  // reset travellers
  removeTravellerServices(booking)

  // reset extensionUpgrade members
  booking.isUpgradedToExtension = false
  booking.extensionDowngradeDate = undefined

  // reset contingents
  booking.contingents = undefined
  booking.contingentTimestamp = undefined

  // varia
  booking.ratioSummaryLoadingError = undefined
}

export const sendBookingFunnelEvent = (booking: bookingStateItem, step: number) => {
  const isStepEventSent = () => {
    if (!booking.sentEvents) {
      booking.sentEvents = []
    }
    return booking.sentEvents.includes(step)
  }
  if (!isStepEventSent()) {
    const payload = {
      travelCode: booking.pricingTravel?.travelCode as string,
      travelName: booking.travel?.name as string,
      noOfTravellers: booking.travellers.length,
    }
    switch (step) {
      case 0:
        sendBeginCheckoutEvent(payload)
        break
      case 1:
        sendCheckoutSelectServicesEvent(payload)
        break
      case 2:
        sendCheckoutTravellerDataEvent(payload)
        break
      case 3:
        sendCheckoutSelectInsuranceEvent(payload)
        break
      case 4:
        sendCheckoutPaymentInfoEvent(payload)
        break
      case 4.5:
        sendCheckoutInitPaymentEvent(payload)
        break
    }
    booking.sentEvents.push(step)
  }
}

export const getApiPayloadFromBooking = (booking: bookingStateItem) => {
  const payload: any = cloneDeep(booking)
  if (payload.pricingTravel?.dates) {
    delete payload.pricingTravel.dates
  }
  return payload
}
